// src/dashboard/Dashboard.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  const menuItems = [
    { name: 'Inspections', path: '/dashboard/inspections' },
    // Add more menu items as needed
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="dashboard-container container mt-5">
      <div className="dashboard-content">
        <h2>Dashboard</h2>
        <div className="row">
          {menuItems.map((item) => (
            <div key={item.name} className="col-md-4 mb-3">
              <button
                className="btn btn-primary w-100"
                onClick={() => handleNavigation(item.path)}
              >
                {item.name}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
