// Home.js
import Services from '../../components/Services/Services';
import Counters from '../../components/Counters';
import Carouzel from '../../components/CardsComponent';
import HeroSection from '../../pages/Home/HeroSection';
import SearchAI from '../../components/searchAI.js';
import HomeNewsSection from '../../pages/Home/HomeSection'; // Import the new HomeNewsSection
import './Home.css';

function Home() {
  return (
    <div className="home-page">
      {/* Hero Section */}
      <HeroSection />

      {/* Search AI Section */}
      <div className="container mt-5 text-center">
        <SearchAI />
      </div>

      {/* Epsilon Group Introduction */}
      <div className="container mt-5 text-center">
        <p className="mb-5 common-text">
          Founded in 2001, Epsilon represents a legacy of maritime excellence, delivering unparalleled crew management solutions.
          We are proud to be an elite provider, offering a comprehensive suite of services including recruitment, crew management,
          manning, pre-vetting, and training, supported by dedicated health services to ensure the well-being of seafarers. With our
          cutting-edge technology and a commitment to exceptional service, Epsilon stands as a trusted partner for some of the
          world&apos;s most demanding shipping companies worldwide. Discover how our professionalism and expertise can elevate your
          maritime operations on our website.
        </p>

        <h2 className="mb-4">Epsilon&apos;s Premier Maritime Solutions and Services</h2>
        <div className="mb-4">
          <hr className="mx-auto" style={{ width: "80px", height: "4px", backgroundColor: "#007bff" }} />
        </div>
        <p className="mb-5 common-text">
          Our services are expertly tailored to meet the distinct needs of our principals, offering unparalleled
          precision and customization. With an uncompromising dedication to quality and reliability, we provide
          an elite portfolio of maritime solutions designed to optimize performance and drive operational
          excellence. Through our innovative approach and deep industry expertise, we empower our clients to
          exceed expectations and set new benchmarks for success in the maritime sector.
        </p>
      </div>

      {/* Services Section */}
      <div className="container-fluid mt-5">
        <Services />
      </div>

      {/* Counters Section */}
      <div className="container-fluid mt-5 p-0">
        <Counters />
      </div>

      {/* Our Commitments Section */}
      <div className="container mt-5 text-center">
        <h2 className="mb-4">Our Commitments</h2>
        <div className="mb-4">
          <hr className="mx-auto" style={{ width: "80px", height: "4px", backgroundColor: "#007bff" }} />
        </div>
        <div className="mb-4"></div>

        {/* Carouzel Section */}
        <div className="container-fluid mt-5 pb-5">
          <Carouzel />
        </div>
      </div>

      {/* News Section - after CardsComponent */}
      <div className="container mt-5">
        <HomeNewsSection />
      </div>
    </div>
  );
}

export default Home;
