import { FaWrench } from 'react-icons/fa';
import { Container } from 'react-bootstrap';
import './TechnologyServices.css';

const TechnologyServices = () => {
  return (
    <div className="technology-services-section">
      <Container className="technology-services mt-5">
        {/* Header Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Technology Services</h1>
          <p className="lead motto">&quot;Trusted Expertise in Tech Solutions&quot;</p>
        </div>

        {/* Under Construction Section */}
        <section className="under-construction-section text-center">
          <FaWrench className="construction-icon mb-4" />
          <h2 className="under-construction-heading">Under Construction</h2>
          <p className="under-construction-text">
            We&apos;re working hard to bring you our Technology Services. Stay tuned for updates!
          </p>
        </section>

        {/* Optional Additional Content Placeholder */}
        {/* Future content can be added here once the services are ready */}
      </Container>
    </div>
  );
};

export default TechnologyServices;
