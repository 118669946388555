import React from 'react';
import { Link } from 'react-router-dom';
import manningServices from './../../assets/Manning-services.webp';
import medicalServices from './../../assets/Medical-services.webp';
import preVettingServices from './../../assets/Pre-vetting-services.webp';
import technicalServices from './../../assets/Technical-services.webp';
import trainingServices from './../../assets/Training-services.webp';
import technologyServices from './../../assets/Technology-services.webp';
import './Services.css'; // Add this for styling

const Services = () => {
  return (
    <div className="container mt-5">
      <div className="row text-center">
        {/* Crew Management & Manning Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <img
              src={manningServices}
              alt="Crew Management & Manning Services"
              className="img-fluid"
              width="600"  // Added width attribute
              height="400" // Added height attribute
            />
            <div className="service-title">Crew Management & Manning Services</div>

            {/* New links in the same style as the "Enhance the skills" link */}
            <div className="service-overlay">
              <Link to="/services/management" className="important-text">
                Crew Management Solutions
              </Link>
              <br />
              <Link to="/services/manning" className="important-text">
                Crew Manning Solutions
              </Link>
            </div>
          </div>
        </div>

        {/* Training Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <Link to="/services/training">
              <img
                src={trainingServices}
                alt="Training Services"
                className="img-fluid"
                width="600"  // Added width attribute
                height="400" // Added height attribute
              />
              <div className="service-title">Training Services</div>
            </Link>
            <div className="service-overlay">
              <p className="important-text">Enhance the skills of your crew</p>
            </div>
          </div>
        </div>

        {/* Pre-vetting Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <Link to="/services/pre-vetting">
              <img
                src={preVettingServices}
                alt="Pre-vetting Services"
                className="img-fluid"
                width="600"  // Added width attribute
                height="400" // Added height attribute
              />
              <div className="service-title">Pre-vetting Services</div>
            </Link>
            <div className="service-overlay">
              <p className="important-text">Ensure compliance before inspections</p>
            </div>
          </div>
        </div>

        {/* Technical Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <Link to="/services/technical">
              <img
                src={technicalServices}
                alt="Technical Services"
                className="img-fluid"
                width="600"  // Added width attribute
                height="400" // Added height attribute
              />
              <div className="service-title">Technical Services</div>
            </Link>
            <div className="service-overlay">
              <p className="important-text">Comprehensive technical management solutions</p>
            </div>
          </div>
        </div>

        {/* Medical Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <Link to="/services/medical">
              <img
                src={medicalServices}
                alt="Medical Services"
                className="img-fluid"
                width="600"  // Added width attribute
                height="400" // Added height attribute
              />
              <div className="service-title">Medical Services</div>
            </Link>
            <div className="service-overlay">
              <p className="important-text">Healthcare solutions for your crew</p>
            </div>
          </div>
        </div>

        {/* Technology Services */}
        <div className="col-md-4 mb-4">
          <div className="service-item position-relative">
            <Link to="/services/technology">
              <img
                src={technologyServices}
                alt="Technology Services"
                className="img-fluid"
                width="600"  // Added width attribute
                height="400" // Added height attribute
              />
              <div className="service-title">Technology Services</div>
            </Link>
            <div className="service-overlay">
              <p className="important-text">Software, cloud, and IT solutions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
