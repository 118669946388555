// src/pages/Countries/Cyprus.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Cyprus.css'; // Custom CSS for Cyprus page
import cyprusImage from '../../assets/Cyprus.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Cyprus = () => {
  return (
    <div className="cyprus-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={cyprusImage} alt="Epsilon in Cyprus" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">From Cyprus to the World</h1>
            <h2>Leading with Integrity and Trust</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Cyprus</h2>
        <hr className="group-divider" />

        <p>
          Our headquarters are located in Cyprus, home to our senior management office. With deep roots in the country’s
          rich maritime heritage, we leverage this strong tradition to guide our global operations. Our commitment to transparency,
          ethical practices, and excellence drives us to meet the needs of both our seafarers and Principals, ensuring the highest
          standards of service across all our endeavors.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="cyprus-contact container mt-5">
        <h3 className="text-center">Contact Epsilon Hellas (OVERSEAS) LTD</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>113-115 Vasileos Konstantinou Str.,<br />MITERA Building, 1st Floor, 3080 Limassol – Cyprus</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+35725266050">+357 25266050</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:crew@epsilonhellas.com">crew@epsilonhellas.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cyprus;
