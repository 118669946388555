import React, { useState, useEffect, useRef } from 'react';
import PublicIcon from '@mui/icons-material/Public'; // Globe icon
import './LanguageSelector.css';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null);

  const languages = [
    { code: 'en', label: 'EN' },
    { code: 'es', label: 'ES' },
    { code: 'fr', label: 'FR' },
    { code: 'de', label: 'DE' },
    { code: 'gr', label: 'GR' },
  ];

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    setIsOpen(false);
  };

  const toggleLanguageOptions = () => {
    setIsOpen(!isOpen);
  };

  // Close the language options when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Provide a default language if undefined
  const currentLanguage = i18n.language ? i18n.language.toUpperCase() : 'EN';

  return (
    <div className="language-selector" ref={selectorRef}>
      <button
        className="language-button"
        onClick={toggleLanguageOptions}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <PublicIcon className="globe-icon" />
        <span className="current-language">{currentLanguage}</span>
      </button>
      {isOpen && (
        <ul className="language-options" role="menu">
          {languages.map(({ code, label }) => (
            <li key={code}>
              <button
                className={`language-option ${
                  i18n.language === code ? 'active' : ''
                }`}
                onClick={() => handleLanguageChange(code)}
                role="menuitem"
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LanguageSelector;
