// src/pages/Countries/Indonesia.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Indonesia.css'; // Custom CSS for Indonesia page
import indonesiaImage from '../../assets/Indonesia.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Indonesia = () => {
  return (
    <div className="indonesia-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={indonesiaImage} alt="Epsilon in Indonesia" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">From Indonesia’s Shores</h1>
            <h2>Delivering World-Class Maritime Professionals</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Indonesia</h2>
        <hr className="group-divider" />

        <p>
          Indonesia, as a quintessential island nation, boasts a rich history of maritime expertise and seafaring traditions. Indonesian
          seafarers are highly sought after by both Western and Asian Principals, offering extensive experience across a wide range of vessels
          and international waters.
        </p>

        <p>
          Epsilon’s Jakarta office is fully equipped to provide officers and crew that meet our stringent quality standards, ensuring that
          our clients receive the highest caliber of maritime professionals, prepared to excel in the global shipping industry.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="indonesia-contact container mt-5">
        <h3 className="text-center">Contact ARJUNA SAMUDERA INDONESIA</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Citi Hub (ex : Harton Tower), 5th floor, Boulevard Artha Gading, Kepala Gading, Jakarta Utara, 14240 DKI Jakarta</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+622122450785">+62 2122450785</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:recruitment@arjunamaritime.id">recruitment@arjunamaritime.id</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indonesia;
