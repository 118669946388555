// MissionAndVision.jsx
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaUsers, FaChalkboardTeacher, FaLightbulb, FaShieldAlt, FaChartLine, FaRegHandshake, FaLeaf, FaHeart, FaBalanceScale, FaStethoscope, FaGlobe } from 'react-icons/fa';
import './MissionAndVision.css';

const MissionAndVision = () => {
  return (
    <div className="epsilon-vision-section">
      <Container className="epsilon-vision mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Our Guiding Principles and Future Vision</h1>
          <p className="lead motto">
            &quot;Fostering Growth, Driving Quality, Shaping the Future of Maritime Labor&quot;
          </p>
        </div>

        {/* Our Mission Section */}
        <section className="mission-section mb-5">
          <h2 className="premium-subheading">Our Mission</h2>
          <p className="lead-text">
            At Epsilon, our mission is to deliver premium, reliable, and fully compliant maritime solutions that empower our clients to achieve unmatched success. We are dedicated to supporting both shipping companies and seafarers by offering services that prioritize efficiency, safety, and operational excellence. Our holistic approach extends beyond traditional recruitment, encompassing comprehensive training and development programs that foster the professional growth of seafarers and elevate the maritime workforce as a whole. Guided by principles of integrity, reliability, and a customer-centric focus, we strive to build enduring partnerships based on trust and professionalism, while contributing to the socio-economic advancement of the communities we serve.
          </p>
        </section>

        {/* Our Vision Section */}
        <section className="vision-section mb-5">
          <h2 className="premium-subheading">Our Vision</h2>
          <p className="lead-text">
            Epsilon&rsquo;s vision is to be the foremost leader in maritime services, recognized globally for our unwavering commitment to quality, safety, and operational superiority. We aim to enhance the efficiency, safety, and sustainability of our clients&apos; operations while fostering the personal and professional growth of seafarers. Driven by our core values of integrity, reliability, and innovation, we are shaping the future of the maritime industry by delivering dynamic crew management solutions and advanced technologies that exceed the evolving demands of our clients. Our goal is to continually elevate industry standards while ensuring the long-term success and sustainability of the global maritime community.
          </p>
        </section>

        {/* Our Commitments Section */}
        <section className="commitments-section">
          <h2 className="premium-subheading text-center mb-5">Our Commitments</h2>
          <Row className="g-4">
            {/* Commitment 1 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaUsers className="commitment-icon" />
                  <h3>Excellence in Human Capital</h3>
                  <p>
                    We are committed to providing highly skilled maritime officers and crew who exemplify professional integrity, personal growth, social responsibility, and technological proficiency. By prioritizing human capital development, we ensure our seafarers are fully equipped to meet the challenges of a dynamic maritime industry.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 2 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaChalkboardTeacher className="commitment-icon" />
                  <h3>State-of-the-Art Training</h3>
                  <p>
                    Epsilon offers world-class training programs supported by modern facilities and expert instructors. Our training initiatives are designed to continuously enhance the skills and competencies of maritime professionals, ensuring they remain at the forefront of industry developments.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 3 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaLightbulb className="commitment-icon" />
                  <h3>Leadership and Innovation</h3>
                  <p>
                    We are dedicated to delivering responsible leadership, cutting-edge technologies, and adaptive crew management solutions that meet and exceed the evolving needs of the maritime sector. Our services remain at the forefront of industry advancements through constant innovation.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 4 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaShieldAlt className="commitment-icon" />
                  <h3>Commitment to Quality and Continuous Improvement</h3>
                  <p>
                    Our unwavering commitment to providing world-class manning and training services consistently exceeds the expectations of our Principals and stakeholders. Through relentless pursuit of excellence, we not only ensure customer satisfaction but also foster the sustainable success of our clients&apos; operations.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 5 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaChartLine className="commitment-icon" />
                  <h3>Regulatory Compliance and Operational Integrity</h3>
                  <p>
                    By adhering to the highest regulatory and statutory standards, we continuously refine our operations to adapt to both internal and external challenges. Our Quality Management System is a cornerstone of our operational integrity, guaranteeing service excellence at every level.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 6 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaRegHandshake className="commitment-icon" />
                  <h3>Developing People, Elevating Standards</h3>
                  <p>
                    We are committed to the continuous development of our workforce, equipping them with the skills and knowledge necessary to maintain the highest standards of quality. Through our fully controlled, world-class establishments, we recruit, train, and nurture crew members who consistently meet the rigorous competencies required by our Principals&rsquo; vessels.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 7 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaLeaf className="commitment-icon" />
                  <h3>Environmental Stewardship</h3>
                  <p>
                    Epsilon is dedicated to environmental stewardship, reducing waste and optimizing resource use. By adopting paperless operations and eco-friendly practices, Epsilon actively works to protect marine environments for future generations.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 8 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaHeart className="commitment-icon" />
                  <h3>Social Responsibility</h3>
                  <p>
                    Epsilon places crew well-being at the forefront, offering top-tier training centers, healthcare, and promoting fair working conditions. By investing in community development, the company supports the maritime workforce through education and healthcare initiatives, ensuring a positive impact on seafarers and their families.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 9 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaBalanceScale className="commitment-icon" />
                  <h3>Economic Resilience</h3>
                  <p>
                    Epsilon enhances maritime sector strength by managing resources responsibly and focusing on long-term growth. This approach supports both business interests and the global maritime community, ensuring resilience and sustainability in operations.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 10 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaStethoscope className="commitment-icon" />
                  <h3>Healthcare</h3>
                  <p>
                    Through Galinos Medical Clinic, Epsilon provides high-standard medical services for seafarers and overseas workers. The clinic delivers efficient and high-quality pre-employment medical examinations and healthcare solutions, ensuring the health and safety of maritime personnel.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Commitment 11 */}
            <Col xs={12} md={6} lg={4}>
              <Card className="commitment-box text-center">
                <Card.Body>
                  <FaGlobe className="commitment-icon" />
                  <h3>Shaping the Future of Maritime Labor</h3>
                  <p>
                    By focusing on the professional growth of our seafarers and maintaining a strong emphasis on service quality, Epsilon plays a pivotal role in shaping the future of maritime labor. Our efforts not only contribute to the success of our clients but also to the sustainability and advancement of the global shipping industry.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default MissionAndVision;
