import {
  FaWrench,
  FaHardHat,
  FaHammer,
  FaScrewdriver,
  FaBolt,
  FaGasPump,
  FaHandHolding,
  FaPaintBrush,
  FaTint,
  FaShip,
  FaAnchor,
  FaCogs,
  FaMoneyCheckAlt,
  FaShieldAlt
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './TechnicalServices.css';

const TechnicalServices = () => {
  return (
    <div className="technical-services-section">
      <Container className="technical-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Onboard Repairs – Riding Teams</h1>
          <p className="lead motto">&quot;Onboard Repair Solutions that Keep Your Fleet Moving&quot;</p>
          <p className="lead-text">
            With over 25 years of experience, we have built a strong and respected reputation with a wide array of esteemed clients, consistently meeting their needs and quickly becoming their trusted partner for reliable, high-quality service.
          </p>
          <p className="lead-text">
            To further support your maintenance requirements, we provide highly skilled personnel for a wide range of facility maintenance and repair projects. Our services are designed to help you achieve your goals in a timely and cost-efficient manner, ensuring that the right expertise is delivered at the right place and time. We specialize in providing expert labor for repairs involving engines, diesel generators, hydraulic systems, boilers, and various types of pumps. Our workforce includes experienced mechanics for comprehensive engine maintenance, as well as experts in piping installation.
          </p>
          <p className="lead-text">
            In addition, we have an on-the-ground team dedicated to understanding your specific needs, organizing and deploying a tailored riding squad, continuously monitoring their progress, and providing you with regular updates.
          </p>
          <p className="lead-text">
            At Epsilon Hellas, we understand that our services are integral to your operational success. We are committed to delivering on specifications and meeting deadlines, ensuring your maintenance and repair projects contribute positively to your overall value chain.
          </p>
        </div>

        {/* Our Highly Qualified Teams Section */}
        <section className="qualified-teams mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Highly Qualified Teams</h2>
          <Row className="g-4">
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaWrench className="team-icon" />
                  <h3>Engineers</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaHardHat className="team-icon" />
                  <h3>Coded Fitter Welders and Platers</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaHammer className="team-icon" />
                  <h3>Fabricators and Pipe Fitters</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaScrewdriver className="team-icon" />
                  <h3>Mechanical Fitters and Turners</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaBolt className="team-icon" />
                  <h3>Sheet Metal Workers and Insulators</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaBolt className="team-icon" />
                  <h3>Electricians</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaGasPump className="team-icon" />
                  <h3>Blasters (Grit and Ultra-High Pressure)</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaCogs className="team-icon" />
                  <h3>Riggers and Scaffolders</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaHandHolding className="team-icon" />
                  <h3>Tank and General Cleaners</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaPaintBrush className="team-icon" />
                  <h3>Painters (Airless Spray and Brush)</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaScrewdriver className="team-icon" />
                  <h3>Plumbers</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="team-box">
                <Card.Body className="text-center">
                  <FaTint className="team-icon" />
                  <h3>Hydraulic Specialists</h3>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Additionally, We Provide Services For Section */}
        <section className="additional-services mb-5">
          <h2 className="premium-subheading text-center mb-4">Additionally, We Provide Services For</h2>
          <Row className="g-4">
            <Col xs={12} sm={6} md={6}>
              <Card className="additional-box">
                <Card.Body className="text-center">
                  <FaShip className="additional-icon" />
                  <h3>Surveys</h3>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <Card className="additional-box">
                <Card.Body className="text-center">
                  <FaAnchor className="additional-icon" />
                  <h3>Docking Repairs</h3>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Service Approach Section */}
        <section className="service-approach mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Service Approach</h2>
          <p className="lead-text">
            Our service approach is focused on addressing your concerns around time, efficiency, and cost, ensuring you remain aligned with your operational targets and plans.
          </p>
          <Row className="g-4">
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body className="text-center">
                  <FaCogs className="approach-icon" />
                  <h3>Time Efficiency</h3>
                  <p>
                    We ensure timely delivery of maintenance and repair projects, minimizing downtime and keeping your fleet operational.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body className="text-center">
                  <FaMoneyCheckAlt className="approach-icon" />
                  <h3>Cost Efficiency</h3>
                  <p>
                    Our cost-effective solutions help you manage your maintenance budget without compromising on quality.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body className="text-center">
                  <FaShieldAlt className="approach-icon" />
                  <h3>Operational Alignment</h3>
                  <p>
                    We align our services with your operational targets, ensuring seamless integration and support.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Contact Section */}
        <section className="contact-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Contact Us</h2>
          <p className="lead-text text-center">
            Ready to keep your fleet moving with our reliable onboard repair solutions? Contact our experts today to learn more about our services.
          </p>
          <div className="text-center">
            <a href="/contact" className="btn btn-primary">Get in Touch</a>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default TechnicalServices;
