// src/pages/Countries/Turkey.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Turkey.css'; // Custom CSS for Turkey page
import turkeyImage from '../../assets/Turkey.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Turkey = () => {
  return (
    <div className="turkey-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={turkeyImage} alt="Epsilon in Turkey" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Connecting Global Principals</h1>
            <h2>With Turkey’s Elite Maritime Talent</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Turkey</h2>
        <hr className="group-divider" />

        <p>
          Turkey has firmly established itself as a leading source of highly qualified officers for ocean-going vessels, with a strong
          reputation for excellence. Turkish officers bring extensive experience, particularly in serving on Western European vessels, and
          their superior performance is increasingly recognized by industry leaders worldwide.
        </p>

        <p>
          Epsilon is proud to be represented in Turkey through Omikron, located on the Asian side of Istanbul. From this strategic location,
          we offer discerning Principals access to an exclusive pool of exceptionally skilled Turkish officers, ensuring a seamless match of
          talent to the demands of the global maritime industry.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="turkey-contact container mt-5">
        <h3 className="text-center">Contact OMIKRON CREW MANAGEMENT</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Bağdat Caddesi No:244, Ergun Apt. Daire: 5, Caddebostan, Kadıköy, İstanbul 34728 – TURKEY</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone 1</h5>
                <p><a href="tel:+902168016484">+90 216 801 64 84</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone 2</h5>
                <p><a href="tel:+902167473474">+90 216 747 34 74</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:crew@omikroncrew.com">crew@omikroncrew.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Turkey;
