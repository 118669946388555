import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/LogoEpsilon.png';
import './Logo.css';

function Logo() {
  const [scrolled, setScrolled] = useState(false); // State to track scroll position

  // Add scroll event listener on component mount
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Get the current scroll position
      if (scrollPosition > 50) { // Change this value to adjust when color changes
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll); // Add event listener

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup listener on component unmount
    };
  }, []);

  return (
    <Link to="/" className="logo-container">
      <img src={logo} alt="Logo" className="logo" width="65" height="65" /> {/* Explicit width and height */}
      <span className={`logo-text ${scrolled ? 'scrolled' : ''}`}>epsilon</span> {/* Conditional class based on scroll */}
    </Link>
  );
}

export default Logo;
