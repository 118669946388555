// src/App.js
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import ScrollToTop from '../src/ScrollTop'; // Import the ScrollToTop component
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import JobApplyButton from './components/JobApplyButton'; // Keep JobApplyButton here
import Login from './components/Login';
import LogoutButton from './components/LogoutButton';
import Dashboard from './dashboard/Dashboard';
import Home from './pages/Home/Home';
import LetsMeetUp from './pages/LetsMeetUp/LetsMeetUp';
import PrivacyNotice from './pages/PrivacyNotice/PrivacyNotice';
import DataProtectionPolicy from './pages/DataProtectionPolicy/DataProtectionPolicy';
import TermsOfUse from './pages/TermOfUse/TermsOfUse';
import CookieStatement from './pages/CookieStatement/CookieStatement';
import LegalStatement from './pages/LegalStatement/LegalStatement';
import ServicesPage from './pages/Services/ServicesPage';
import News from './pages/News/News';
import NewsDetail from './pages/NewsDetail/NewsDetail';

// Footer links
import AboutUs from './pages/About/AboutUs';
import MissionAndVision from './pages/MissionAndVision/MissionAndVision';
import OurTeam from './pages/OurTeam/OurTeam';
import OurGlobalPresence from './pages/GlobalPresence/OurGlobalPresence';
import Commitments from './pages/Commitments/Commitments';
import CompanySustainability from './pages/CompanySustainability/CompanySustainability';

// RND page
import RND from './pages/RND/RND';

// Country-specific pages
import ThePhilippines from './pages/Countries/ThePhilippines';
import Ukraine from './pages/Countries/Ukraine';
import Russia from './pages/Countries/Russia';
import Romania from './pages/Countries/Romania';
import Turkey from './pages/Countries/Turkey';
import Greece from './pages/Countries/Greece';
import Cyprus from './pages/Countries/Cyprus';
import Indonesia from './pages/Countries/Indonesia';
import Vietnam from './pages/Countries/Vietnam';
import Georgia from './pages/Countries/Georgia';

// Service pages
import ManningServices from './pages/Services/ManningServices/ManningServices';
import TrainingServices from './pages/Services/TrainingServices/TrainingServices';
import PreVettingServices from './pages/Services/PreVettingServices/PreVettingServices';
import TechnicalServices from './pages/Services/TechnicalServices/TechnicalServices';
import MedicalServices from './pages/Services/MedicalServices/MedicalServices';
import TechnologyServices from './pages/Services/TechnologyServices/TechnologyServices';

// Import Management Services page
import ManagementServices from './pages/Services/ManagementServices/ManagementServices';

// Import the ProtectedRoute component
import ProtectedRoute from './components/ProtectedRoute';

// Import the dashboard components
import InspectionsList from './dashboard/InspectionsList';
import CreateInspection from './dashboard/CreateInspection';
import InspectionDetail from './dashboard/InspectionDetail';

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Ensures that the page scrolls to the top on route change */}
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  // State for showing the Back to Top button
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Function to handle scrolling
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Paths where the header and footer should not be displayed
  const noHeaderFooterRoutes = ['/dashboard'];

  // Determine whether to show the header and footer
  const shouldShowHeaderFooter = !noHeaderFooterRoutes.some((path) =>
    location.pathname.startsWith(path)
  );

  // Hide Job Apply button on LetsMeetUp page
  const shouldShowJobApplyButton = location.pathname !== '/contact';

  return (
    <div className="App d-flex flex-column min-vh-100">
      {shouldShowHeaderFooter && <Header />}
      <main className="flex-fill">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogoutButton />} />

          {/* Protected Route for Dashboard */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <DashboardRoutes />
              </ProtectedRoute>
            }
          />

          {/* Other routes */}
          <Route path="/contact" element={<LetsMeetUp />} /> {/* LetsMeetUp page */}
          <Route path="/privacy" element={<PrivacyNotice />} />
          <Route path="/data-protection" element={<DataProtectionPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/cookie" element={<CookieStatement />} />
          <Route path="/legal" element={<LegalStatement />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:slug" element={<NewsDetail />} />

          {/* Footer links */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/mission-and-vision" element={<MissionAndVision />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/our-global-presence" element={<OurGlobalPresence />} />
          <Route path="/commitments" element={<Commitments />} />
          <Route path="/sustainability" element={<CompanySustainability />} />

          {/* RND page */}
          <Route path="/rnd" element={<RND />} />

          {/* Country-specific pages */}
          <Route path="/the-philippines" element={<ThePhilippines />} />
          <Route path="/ukraine" element={<Ukraine />} />
          <Route path="/russia" element={<Russia />} />
          <Route path="/romania" element={<Romania />} />
          <Route path="/turkey" element={<Turkey />} />
          <Route path="/greece" element={<Greece />} />
          <Route path="/cyprus" element={<Cyprus />} />
          <Route path="/indonesia" element={<Indonesia />} />
          <Route path="/vietnam" element={<Vietnam />} />
          <Route path="/georgia" element={<Georgia />} />

          {/* Service-specific pages */}
          <Route path="/services/manning" element={<ManningServices />} />
          <Route path="/services/training" element={<TrainingServices />} />
          <Route path="/services/pre-vetting" element={<PreVettingServices />} />
          <Route path="/services/technical" element={<TechnicalServices />} />
          <Route path="/services/medical" element={<MedicalServices />} />
          <Route path="/services/technology" element={<TechnologyServices />} />

          {/* Management Services page */}
          <Route path="/services/management" element={<ManagementServices />} />
        </Routes>
      </main>

      {shouldShowHeaderFooter && shouldShowJobApplyButton && (
        <div className="job-apply-container container my-3">
          <JobApplyButton />
        </div>
      )}

      {shouldShowHeaderFooter && <Footer />}

      {/* Cookie Consent */}
      <CookieConsent
        location="bottom"
        buttonText="I accept"
        cookieName="cookieConsent"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      {/* Back to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="scroll-to-top"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            padding: '10px 20px',
            backgroundColor: '#0c4a8c',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 1000,
          }}
        >
          ↑ Back to Top
        </button>
      )}
    </div>
  );
}

// Define dashboard routes
const DashboardRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="inspections" element={<InspectionsList />} />
    <Route path="inspections/new" element={<CreateInspection />} />
    <Route path="inspections/:id" element={<InspectionDetail />} />
    {/* Add more dashboard routes as needed */}
  </Routes>
);

export default App;
