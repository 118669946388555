// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Example: Fetch user data on component mount
  useEffect(() => {
    // Replace with your actual authentication logic
    const fetchUser = async () => {
      try {
        const response = await fetch('/api/auth/user'); // Example API endpoint
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []); // Empty dependency array ensures this runs once on mount

  const login = (userData) => {
    setUser(userData);
    // Additional login logic if needed
  };

  const logout = () => {
    setUser(null);
    // Additional logout logic if needed
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
