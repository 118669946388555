import {
  FaUsers,
  FaMoneyCheckAlt,
  FaSearchPlus,
  FaPlaneDeparture,
  FaShieldAlt,
  FaPhone,
  FaBookOpen,
  FaHandshake,
  FaBalanceScale,
  FaTools,
  FaHeartbeat
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './ManagementServices.css';

const ManagementServices = () => {
  return (
    <div className="management-services-section">
      <Container className="management-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Crew Management Services</h1>
          <p className="lead motto">&quot;Reliable Crew Management for Smooth and Cost-Effective Operations&quot;</p>
          <p className="lead-text">
            Our Crew Management Services offer a comprehensive and fully integrated solution designed to manage all aspects of crewing operations. By taking on the complexities and responsibilities that typically consume significant resources—time, money, and energy—our services enable Ship Owners and Managers to focus on their core operational priorities. Through seamless coordination between our Operations, Travel Agency, Training, and Accounts departments, we ensure the efficient and smooth deployment of crew for your vessels.
          </p>
          <p className="lead-text">
            Recognizing that crew management is both highly complex and critically important to the operational efficiency of any vessel, Epsilon meticulously plans and delivers solutions that provide peace of mind for Ship Owners and Managers. At the same time, we maintain full transparency and ensure that clients retain complete control over their crewing operations.
          </p>
          <p className="lead-text">
            Our Crew Management scheme operates on a fixed monthly lump sum, agreed annually, covering a wide range of essential services. This all-inclusive package is tailored to meet the specific needs of the Principal and typically includes the following:
          </p>
        </div>

        {/* Key Services Section */}
        <section className="premium-features">
          <h2 className="premium-subheading text-center mb-5">Our Comprehensive Services</h2>
          <Row className="text-center g-4">
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaMoneyCheckAlt className="feature-icon" />
                  <h3>Crew Wages</h3>
                  <p>In accordance with the selected coverage (CBA or non-CBA) and the Principal&apos;s preferred crew nationality.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaSearchPlus className="feature-icon" />
                  <h3>Recruitment Expenses</h3>
                  <p>Inclusive of visa arrangements, domestic travel, STCW documentation, medical examinations, company uniforms, safety shoes, winter gear, and safety equipment.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaPlaneDeparture className="feature-icon" />
                  <h3>Crew Travel Costs</h3>
                  <p>All joining and repatriation ticket expenses for the entire crew.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaBookOpen className="feature-icon" />
                  <h3>Crew Victualing</h3>
                  <p>Full provisioning costs for the crew during their service period.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaShieldAlt className="feature-icon" />
                  <h3>Insurance Coverage</h3>
                  <p>Primary insurance covering up to the standard P&amp;I deductible.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaPhone className="feature-icon" />
                  <h3>Communication Charges</h3>
                  <p>All outbound communication costs incurred by Epsilon.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaHandshake className="feature-icon" />
                  <h3>Cash to Master</h3>
                  <p>Inclusive of bank charges, insurance costs, and exchange rate differences.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaBalanceScale className="feature-icon" />
                  <h3>Advanced Training</h3>
                  <p>Including Bridge Resource Management (BRM), Engine Room Resource Management (ERM), and ECDIS-specific training.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaTools className="feature-icon" />
                  <h3>In-House and CBT</h3>
                  <p>Continuous development through tailored training programs.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Additional Information Section */}
        <section className="additional-info mt-5">
          <h2 className="premium-subheading text-center mb-5">The Benefits of Our One-Stop-Shop Approach</h2>
          <Row className="text-center g-4">
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaHeartbeat className="benefit-icon" />
                  <h3>Reduced Capital Expenditure</h3>
                  <p>Enjoy minimal capital outlay with a predictable, fixed monthly cost structure, allowing for better financial planning.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={3} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaUsers className="benefit-icon" />
                  <h3>Enhanced Crew Availability</h3>
                  <p>Our integrated approach improves both the availability and retention of officers and crew, ensuring a stable and reliable workforce.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default ManagementServices;
