import React from 'react';
import hooverShip from '../../assets/Home.webp';
import hooverShipSmall from '../../assets/Home-small.webp'; // Smaller version for mobile
import hooverShipMedium from '../../assets/Home-medium.webp'; // Medium version for tablets
import './HeroSection.css';

function HeroSection() {
  return (
    <div className="hero-section">
      <div className="container-fluid p-0">
        <div className="position-relative">
          <picture>
            {/* Serve the appropriate image based on the screen size */}
            <source srcSet={hooverShip} type="image/webp" media="(min-width: 1200px)" />
            <source srcSet={hooverShipMedium} type="image/webp" media="(min-width: 768px)" />
            <source srcSet={hooverShipSmall} type="image/webp" media="(max-width: 767px)" />
            <img
              src={hooverShipSmall} // Fallback image
              alt="Hoover Ship"
              className="img-fluid w-100"
              width="1920"
              height="1080"
              loading="lazy" // Lazy loading for better performance
              decoding="async" // Decode asynchronously for faster rendering
            />
          </picture>
          <div className="hero-text text-white text-center">
            <h1 className="display-4">Welcome to Epsilon</h1>
            <p className="lead">“Innovating Today, Leading Tomorrow in Maritime Solutions”</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
