import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import { stripHtml } from 'string-strip-html';
import DOMPurify from 'dompurify';
import './NewsDetail.css'; // Import the CSS file

const NewsDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://epsilon.wfy24.com';

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const apiUrl = `${API_BASE_URL}/api/articles/news/${slug}/`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch article, status: ${response.status}`);
        }
        const data = await response.json();
        setArticle(data);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError('An error occurred while fetching the article.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [API_BASE_URL, slug]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-4">
        <div className="spinner-border" role="status" aria-hidden="true"></div>
        <span className="ms-2">Loading article...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        {error}
      </div>
    );
  }

  if (!article) {
    return <p className="text-center">Article not found.</p>;
  }

  const sanitizedContent = DOMPurify.sanitize(article.content);
  const formattedDate = article.published_date
    ? new Date(article.published_date).toLocaleDateString('en-GB')
    : 'Date not available';

  const plainTextContent = stripHtml(article.content).result;
  const excerpt = article.meta_description || (plainTextContent ? plainTextContent.substring(0, 150) : '');

  return (
    <div className="container mt-5">
      <Helmet>
        <title>{article.meta_title || article.title} | Epsilon News</title>
        <meta name="description" content={excerpt} />
        <meta name="keywords" content={article.meta_keywords || 'default, keywords'} />
        <meta property="og:title" content={article.meta_title || article.title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={article.image || 'https://wfy24.com/default_image.jpg'} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://wfy24.com/news/${article.slug}`} />
      </Helmet>

      <h1 className="text-center mb-4">{article.title}</h1>
      <div className="text-center">
        <img
          src={article.image || 'https://wfy24.com/default_image.jpg'}
          alt={article.title || 'Default'}
          className="img-fluid mb-4"
        />
      </div>
      <div
        className="article-content"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      ></div>
      <div className="text-muted text-end">
        <small>Published on: {formattedDate}</small>
      </div>
    </div>
  );
};

export default NewsDetail;
