import {
  FaCheckCircle,
  FaClipboardList,
  FaSearch,
  FaBook,
  FaShieldAlt,
  FaUsers,
  FaTools,
  FaHandshake,
  FaChartLine,
  FaRegLightbulb
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './PreVettingServices.css';

const PreVettingServices = () => {
  return (
    <div className="pre-vetting-services-section">
      <Container className="pre-vetting-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Pre-Vetting Services</h1>
          <p className="lead motto">&quot;Ensuring Compliance, Minimizing Risks, Elevating Standards.&quot;</p>
          <p className="lead-text">
            Epsilon Group offers comprehensive Pre-Vetting Services to support our clients in navigating the complexities of RightShip inspections and ensuring operational excellence. RightShip inspections have become increasingly rigorous, pushing ship managers beyond mere compliance and demanding new standards both onboard and ashore. Our tailored Pre-Vetting Services are designed to mitigate risks, ensure full compliance, and optimize readiness, enabling our clients to confidently face these evolving challenges.
          </p>
        </div>

        {/* Our Pre-Vetting Portfolio Section */}
        <section className="pre-vetting-portfolio mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Pre-Vetting Portfolio</h2>
          <p className="lead-text">
            Our pre-vetting services offer a thorough and genuine assessment of the vessel&apos;s status ahead of formal RightShip inspections. We focus on enhancing the likelihood of achieving high inspection scores by providing real-time insights and actionable recommendations.
          </p>
          <Row className="text-center g-4">
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="portfolio-box flex-fill">
                <Card.Body>
                  <FaClipboardList className="portfolio-icon" />
                  <h3>Confidential Inspections</h3>
                  <p>A thorough inspection conducted exclusively for our clients, aligned with the latest RightShip inspection standards.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="portfolio-box flex-fill">
                <Card.Body>
                  <FaSearch className="portfolio-icon" />
                  <h3>Onboard Assessments</h3>
                  <p>An in-depth evaluation of all onboard areas, engaging all relevant teams to ensure a successful inspection outcome.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="portfolio-box flex-fill">
                <Card.Body>
                  <FaCheckCircle className="portfolio-icon" />
                  <h3>Findings and Reporting</h3>
                  <p>Immediate sharing of inspection findings followed by a detailed report, enabling swift corrective actions.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p className="lead-text mt-4">
            Beyond pre-vetting inspections, we offer a range of complementary services, including crew training, system reviews, audits, and performance consulting. These services are available as standalone offerings or as part of a comprehensive package, providing our clients with the flexibility to enhance both compliance and operational efficiency.
          </p>
        </section>

        {/* Our Approach Section */}
        <section className="our-approach mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Approach: Three Steps to Pre-Vetting Excellence</h2>
          <Row className="g-4">
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body>
                  <FaBook className="approach-icon" />
                  <h3>Preparation</h3>
                  <p>
                    Our preparation process begins well in advance of the vessel visit, with a meticulous performance review. Our experts conduct a thorough assessment of all relevant documentation, identifying any potential gaps and ensuring that no critical details are overlooked. By reviewing the vessel&apos;s track record, we proactively address deficiencies that can be easily rectified ahead of time. This early-stage performance check optimizes inspection readiness, enabling a smooth and successful vetting process.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body>
                  <FaShieldAlt className="approach-icon" />
                  <h3>Pre-Vetting Inspection</h3>
                  <p>
                    Our highly experienced inspectors carry out a thorough inspection, covering all areas of the vessel. This includes comprehensive operational tests, evaluations of crew performance, and meticulous reviews of all relevant records. Upon completion, we provide a detailed list of findings along with an in-depth report, offering clear guidance for corrective actions and targeted performance improvements. This ensures our clients are fully prepared for formal inspections and can address any potential issues in advance.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="approach-box">
                <Card.Body>
                  <FaUsers className="approach-icon" />
                  <h3>Onboard Training</h3>
                  <p>
                    In addition to the inspection, we provide specialized onboard training focused on critical areas such as hatch cover maintenance, emergency procedures, hazardous materials handling, vetting preparation, and helicopter operations. This targeted training ensures that your crew is well-prepared and equipped to meet the demands of future inspections, enhancing both safety and operational efficiency.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p className="lead-text mt-4">
            This structured, three-step approach guarantees that your vessel is thoroughly prepared to meet the stringent requirements of RightShip inspections, while simultaneously enhancing operational efficiency and elevating crew readiness.
          </p>
        </section>

        {/* Additional Services Section */}
        <section className="additional-services mb-5">
          <h2 className="premium-subheading text-center mb-4">Additional Services</h2>
          <p className="lead-text">
            Our additional services include comprehensive system reviews and gap analyses, designed to identify necessary improvements and provide clear guidance to enhance both vessel and company performance scores. We also offer ongoing audits and assessments to ensure that identified issues are effectively addressed over time, fostering continuous improvement and long-term operational excellence.
          </p>
        </section>

        {/* Why Choose Us Section */}
        <section className="why-choose-us mb-5">
          <h2 className="premium-subheading text-center mb-4">Why Choose Us for Pre-Vetting Inspections</h2>
          <p className="lead-text">
            With over two decades of experience since 2001, we specialize in delivering niche pre-vetting services to a diverse international clientele, covering a wide range of fleet types. Our exclusive team of inspectors and proprietary technology ensure high-quality, precise inspections. We offer real-time feedback to vessel owners, along with customizable and innovative solutions tailored to your specific needs. Our deep understanding of commercial priorities, closely coordinated operations, extensive industry network, and cutting-edge insights allow us to provide unmatched service, helping you stay ahead in an ever-evolving maritime environment.
          </p>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Benefits of Pre-Vetting Services</h2>
          <Row className="text-center g-4">
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaChartLine className="benefit-icon" />
                  <h3>Risk Mitigation</h3>
                  <p>Cost-efficient awareness of potential inspection risks.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaShieldAlt className="benefit-icon" />
                  <h3>Enhanced Compliance</h3>
                  <p>Identification of practice gaps and support for corrective actions to enhance compliance with RightShip requirements.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaRegLightbulb className="benefit-icon" />
                  <h3>Continuous Improvement</h3>
                  <p>Real-time feedback and detailed reporting to enable ongoing improvement in safety management and operational practices.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaHandshake className="benefit-icon" />
                  <h3>Expert Guidance</h3>
                  <p>Access to our seasoned industry experts, with hands-on experience in dry and wet segments, providing privileged access to formal inspection expertise.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="benefit-box flex-fill">
                <Card.Body>
                  <FaUsers className="benefit-icon" />
                  <h3>Culture of Preparedness</h3>
                  <p>Foster a culture of continuous learning and inspection preparedness within the organization.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Our Inspectors Section */}
        <section className="our-inspectors mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Inspectors</h2>
          <p className="lead-text">
            Our Pre-Vetting Services are led by a team of seasoned industry professionals located in key maritime hubs, including Manila, Qingdao, Jakarta, and Constanta. With deep expertise in DRY BMS, RightShip, and other quality assurance standards, our inspectors bring extensive hands-on experience to ensure each inspection is comprehensive, professional, and customized to meet the unique needs of our clients.
          </p>
          <Row className="g-4">
            <Col xs={12} md={6}>
              <Card className="inspector-box">
                <Card.Body>
                  <FaUsers className="inspector-icon" />
                  <h3>Vettings Experts</h3>
                  <p>
                    Each inspector is highly experienced and actively involved in the vetting process, ensuring thorough and up-to-date assessments.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="inspector-box">
                <Card.Body>
                  <FaTools className="inspector-icon" />
                  <h3>Skilled Trainers</h3>
                  <p>
                    In addition to their inspection duties, all our inspectors serve as trainers, delivering practical, hands-on training to improve crew competence and readiness.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <p className="lead-text mt-4">
            Their dual role as both inspectors and trainers guarantees that our clients receive not only precise evaluations but also valuable knowledge transfer to enhance overall operational performance.
          </p>
        </section>

        {/* Contact Section */}
        <section className="contact-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Contact Us</h2>
          <p className="lead-text text-center">
            Ready to elevate your vessel&apos;s compliance and operational standards? Contact our experts today to learn more about our Pre-Vetting Services.
          </p>
          <div className="text-center">
            <a href="/contact" className="btn btn-primary">Get in Touch</a>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default PreVettingServices;
