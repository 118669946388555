// src/pages/Countries/Romania.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Romania.css'; // Custom CSS for Romania page
import romaniaImage from '../../assets/Romania.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Romania = () => {
  return (
    <div className="romania-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={romaniaImage} alt="Epsilon in Romania" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Honoring Romania’s Maritime Tradition</h1>
            <h2>Delivering Global Excellence</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Romania</h2>
        <hr className="group-divider" />

        <p>
          In recent years, the number of European seafarers has seen a decline, yet certain nations continue to uphold their proud seafaring traditions
          into the new millennium. Romania stands as a shining example, maintaining the high standards and quality that European seafarers are known for.
        </p>

        <p>
          Epsilon is honored to have a presence in Romania through its office in Constanta, the country's traditional source of seafaring talent and a
          prominent center of maritime education. From this strategic location, we continue to connect highly skilled Romanian seafarers with global
          opportunities, ensuring excellence in the maritime industry.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="romania-contact container mt-5">
        <h3 className="text-center">Contact Epsilonhellas Overseas LTD</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>111 Ion Lahovari Street, 900588, Constanta, Romania</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+40372727141">+40 372727141-2</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:crew@epsilonhellas.ro">crew@epsilonhellas.ro</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Romania;
