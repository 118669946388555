// src/dashboard/QuestionComponent.jsx

import React, { useState, useEffect } from 'react';
import api from '../api';

const QuestionComponent = ({ question, inspectionId }) => {
  const [answer, setAnswer] = useState(null);
  const [textAnswer, setTextAnswer] = useState('');
  const [selectedChoice, setSelectedChoice] = useState('');
  const [comments, setComments] = useState('');
  const [recommendations, setRecommendations] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAnswer = async () => {
      try {
        const response = await api.get('/api/answers/', {
          params: {
            inspection: inspectionId,
            question: question.id,
          },
        });
        if (response.data.length > 0) {
          const existingAnswer = response.data[0];
          setAnswer(existingAnswer);
          setTextAnswer(existingAnswer.text_answer || '');
          setSelectedChoice(existingAnswer.selected_choice || '');
          setComments(existingAnswer.comments || '');
          setRecommendations(existingAnswer.recommendations || '');
        }
      } catch (error) {
        console.error('Error fetching answer:', error);
        setError('Failed to load answer. Please try again.');
      }
    };

    fetchAnswer();
  }, [inspectionId, question.id]);

  const handleSave = async () => {
    try {
      const payload = {
        inspection: inspectionId,
        question: question.id,
        text_answer: textAnswer,
        selected_choice: selectedChoice,
        comments,
        recommendations,
      };

      if (answer) {
        // Update existing answer
        await api.put(`/api/answers/${answer.id}/`, payload);
      } else {
        // Create new answer
        await api.post('/api/answers/', payload);
      }
      setError('');
    } catch (error) {
      console.error('Error saving answer:', error);
      setError('Failed to save answer. Please try again.');
    }
  };

  return (
    <div className="mb-4">
      <p>
        <strong>
          {question.number}: {question.text}
        </strong>
      </p>
      {question.question_type === 'text' ? (
        <div className="mb-3">
          <label htmlFor={`text-answer-${question.id}`} className="form-label">
            Your Answer
          </label>
          <textarea
            id={`text-answer-${question.id}`}
            className="form-control"
            value={textAnswer}
            onChange={(e) => setTextAnswer(e.target.value)}
          />
        </div>
      ) : (
        <div className="mb-3">
          <label htmlFor={`choice-answer-${question.id}`} className="form-label">
            Select an Option
          </label>
          <select
            id={`choice-answer-${question.id}`}
            className="form-select"
            value={selectedChoice}
            onChange={(e) => setSelectedChoice(e.target.value)}
          >
            <option value="">Select an option</option>
            {question.choices.map((choice) => (
              <option key={choice.id} value={choice.id}>
                {choice.text}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Comments */}
      <div className="mb-3">
        <label htmlFor={`comments-${question.id}`} className="form-label">
          Comments
        </label>
        <textarea
          id={`comments-${question.id}`}
          className="form-control"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
      {/* Recommendations */}
      <div className="mb-3">
        <label htmlFor={`recommendations-${question.id}`} className="form-label">
          Recommendations
        </label>
        <textarea
          id={`recommendations-${question.id}`}
          className="form-control"
          value={recommendations}
          onChange={(e) => setRecommendations(e.target.value)}
        />
      </div>
      {/* Error Message */}
      {error && <div className="alert alert-danger">{error}</div>}
      {/* Save Button */}
      <button className="btn btn-success" onClick={handleSave}>
        Save Answer
      </button>
    </div>
  );
};

export default QuestionComponent;
