// src/dashboard/InspectionDetail.jsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';
import QuestionComponent from './QuestionComponent';

const InspectionDetail = () => {
  const { id } = useParams();
  const [inspection, setInspection] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInspection = async () => {
      try {
        const response = await api.get(`/api/inspections/${id}/`);
        setInspection(response.data);
      } catch (error) {
        console.error('Error fetching inspection:', error);
        setError('Failed to load inspection. Please try again.');
      }
    };

    fetchInspection();
  }, [id]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!inspection) {
    return (
      <div className="d-flex justify-content-center align-items-center my-4">
        <div className="spinner-border" role="status" aria-hidden="true"></div>
        <span className="ms-2">Loading inspection...</span>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h2>
        Inspection of {inspection.vessel.name} on {inspection.date}
      </h2>
      {inspection.questionnaire.sections.map((section) => (
        <div key={section.id}>
          <h3>{section.name}</h3>
          {section.subsections.map((subsection) => (
            <div key={subsection.id}>
              <h4>{subsection.name}</h4>
              {subsection.questions.map((question) => (
                <QuestionComponent
                  key={question.id}
                  question={question}
                  inspectionId={inspection.id}
                />
              ))}
            </div>
          ))}
          {section.questions.map((question) => (
            <QuestionComponent
              key={question.id}
              question={question}
              inspectionId={inspection.id}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default InspectionDetail;
