// src/pages/Countries/Georgia.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Georgia.css'; // Custom CSS for Georgia page
import georgiaImage from '../../assets/Georgia.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Georgia = () => {
  return (
    <div className="georgia-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={georgiaImage} alt="Epsilon in Georgia" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Batumi’s Maritime Talent</h1>
            <h2>Georgia’s Gateway to Global Shipping</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Georgia</h2>
        <hr className="group-divider" />

        <p>
          Georgia’s maritime heritage traces its origins to the 19th century, and today, the nation is emerging as a key player in
          the global shipping industry. With a rapidly growing community of seafarers and significant investments in maritime training
          infrastructure, Georgia is positioning itself as one of the foremost labor-supplying nations in the Black Sea region.
        </p>
        <p>
          Epsilon’s office is strategically located in the picturesque coastal city of Batumi. Staffed by highly respected professionals
          from the local maritime sector, we have privileged access to Georgia’s exceptional pool of talent, ensuring that we consistently
          provide top-tier human resources to meet the demands of the global maritime industry.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="georgia-contact container mt-5">
        <h3 className="text-center">Contact Epsilon Georgia</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Zhiuli Shartava ave 32, 6000 Batumi, Georgia</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+995555333016">+995 555 333 016</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:cv@epsilongeorgia.com">cv@epsilongeorgia.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Georgia;
