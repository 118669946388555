import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import './MyEpsilonButton.css'; // Import the CSS file for styles

const MyEpsilonButton = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <div className="myEpsilon-wrapper d-flex justify-content-end">
      {user ? (
        <>
          <span className="header-link">Welcome, {user.username}</span>
          <button className="btn btn-outline-dark" onClick={logout}>Logout</button>
        </>
      ) : (
        <Link to="/login" className="btn myEpsilon-btn">
          <div className="myEpsilon-content d-flex flex-column align-items-center">
            <FontAwesomeIcon icon={faUser} /> {/* User icon */}
            <span>myEpsilon</span> {/* Button text */}
          </div>
        </Link>
      )}
    </div>
  );
};

export default MyEpsilonButton;
