import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Ensure this file exists

const Footer = () => {
  return (
    <footer className="footer bg-light py-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul className="nav justify-content-center mb-3">
              {/* Navigation Links can be added here later */}
            </ul>
          </div>
        </div>

        {/* Company Information and Social Media */}
        <div className="row align-items-center mb-3">
          <div className="col-md-6 text-center text-md-left">
            <p className="mb-0">©{new Date().getFullYear()} Epsilon Hellas (Overseas) Ltd, its subsidiaries and affiliates trading as 'Epsilon Group'. All rights reserved.</p>
          </div>
          <div className="col-md-6 text-center text-md-right">
            <div className="social-buttons">
              <a href="https://www.facebook.com/epsiloncrew" target="_blank" rel="noopener noreferrer" className="social-link mx-2" aria-label="Facebook">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link mx-2" aria-label="Twitter">
                <i className="fab fa-x-twitter"></i>
              </a>
              <a href="https://www.linkedin.com/company/epsilon-hellas-crew-management-&-training/mycompany/" target="_blank" rel="noopener noreferrer" className="social-link mx-2" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link mx-2" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Legal Links */}
        <div className="row">
          <div className="col-12 text-center">
            <p className="mb-5" style={{fontSize: '20px', fontWeight: 'bold', color: '#007bff'}}>
              Designed by <Link to="/rnd" className="rnd-link" style={{textDecoration: 'none'}}>RND</Link>
            </p>
            <div className="d-flex justify-content-center flex-wrap">
              <Link to="/terms-of-use" className="legal-link mx-2">Terms of Use</Link>
              <span className="separator mx-1">|</span>
              <Link to="/privacy" className="legal-link mx-2">Privacy Notice</Link>
              <span className="separator mx-1">|</span>
              <Link to="/data-protection" className="legal-link mx-2">Data Protection</Link>
              <span className="separator mx-1">|</span>
              <Link to="/cookie" className="legal-link mx-2">Cookie Statement</Link>
              <span className="separator mx-1">|</span>
              <Link to="/legal" className="legal-link mx-2">Legal</Link>
            </div>
          </div>
        </div>
      </div>
      {/* Add line at the bottom */}
      <div className="footer-end-line"></div>
    </footer>
  );
};

export default Footer;
