// AboutUs.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
  FaShip,
  FaGlobe,
  FaBuilding,
  FaStar,
  FaClinicMedical,
  FaIndustry,
  FaUsers,
  FaSmile
} from 'react-icons/fa';
import './AboutUs.css';

const AboutUs = () => {
  // Dynamically fetch the current year
  const currentYear = new Date().getFullYear();

  const milestones = [
    {
      year: '1976',
      title: 'The Beginning',
      description: "Epsilon's journey begins with a passion for crewing excellence. The company is established with a focus on providing top-tier crew management services, laying the foundation for future leadership in the maritime industry.",
      icon: <FaShip />,
    },
    {
      year: '2001',
      title: 'Epsilon Officially Founded',
      description: "Epsilon is officially founded and begins operations, providing exceptional crew management solutions tailored to the diverse needs of maritime clients. The company focuses on connecting highly skilled seafarers with leading shipping companies worldwide.",
      icon: <FaGlobe />,
    },
    {
      year: '2006',
      title: 'Expansion into Training',
      description: "Recognizing that the development of seafarers is at the heart of its business, Epsilon establishes dedicated training centers. These centers are aimed at enhancing the skills and expertise of seafarers, ensuring they are well-prepared for the demands of the industry. Ongoing development of skilled seafarers remains a cornerstone of Epsilon’s services.",
      icon: <FaBuilding />,
    },
    {
      year: '2010-2015',
      title: 'Growth and Industry Recognition',
      description: "Epsilon grows into a global leader in maritime services, managing over 900 vessels and deploying more than 8,200 crew members at any given time, showcasing the company’s ability to meet the needs of prestigious shipping companies.\n\nThe company becomes a trusted partner to more than 170 corporate clients for training and over 60 clients for crewing services, further cementing its reputation in the maritime industry.",
      icon: <FaStar />,
    },
    {
      year: '2016',
      title: 'Global Expansion',
      description: "Epsilon establishes branch offices and manning agencies globally, expanding its footprint to offer comprehensive maritime services. The company also opens a dedicated clinic in Manila, proving its commitment to client and crew well-being.",
      icon: <FaClinicMedical />,
    },
    {
      year: '2020',
      title: 'Over 15 Global Offices',
      description: "By this year, Epsilon operates 15 offices worldwide, staffed by a dedicated team of over 340 professionals. The company trains more than 2,000 seafarers monthly, ensuring they meet the highest standards in the industry.",
      icon: <FaIndustry />,
    },
    {
      year: `Present Day (${currentYear})`, // Dynamic Year
      title: 'Leadership in Maritime Services',
      description: "Epsilon is now recognized as a leading provider of maritime services. The company continues to expand its offerings, including recruitment, crew management, pre-vetting inspections, crew training, technical support, and medical services.\n\nEpsilon operates in 18 global offices with over 340 professionals, training more than 2,000 seafarers monthly and serving 170 corporate clients for training and 60 for crewing.\n\nWith a vast network of prestigious and demanding shipping companies worldwide, Epsilon operates fully-controlled offices in nine countries, supported by advanced technology and a strong ethical foundation, delivering customized services that prioritize client satisfaction and seafarer well-being.",
      icon: <FaUsers />,
    },
  ];

  return (
    <div className="about-us-section">
      <Container className="about-us mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Our Story</h1>
          <p className="lead motto">"Building the Future: Epsilon’s Story of Ambition, Achievement, and Growth"</p>
        </div>

        {/* Milestones Timeline */}
        <section className="milestones-section">
          <Row className="g-4">
            {milestones.map((milestone, index) => (
              <Col xs={12} md={6} lg={4} key={index}>
                <Card className="milestone-box">
                  <Card.Body>
                    <div className="milestone-icon">
                      {milestone.icon}
                    </div>
                    <h3 className="milestone-year">{milestone.year}</h3>
                    <h4 className="milestone-title">{milestone.title}</h4>
                    <p className="milestone-description">
                      {milestone.description.split('\n').map((str, idx) => (
                        <span key={idx}>
                          {str}
                          <br />
                          <br />
                        </span>
                      ))}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </section>

        {/* Closing Statement */}
        <section className="closing-statement text-center mt-5">
          <p className="lead-text">
            At Epsilon, we believe that our people are the heart of our success. It is their passion, expertise, and dedication that drive us forward and help us deliver excellence to our clients every day. With over 36,000 seafarers in our network, 18 offices across 10 countries, and partnerships with over 120 principals managing more than 1,100 vessels, we are committed to providing the very best in crew management and maritime solutions. Our people are our greatest strength, and it is through their hard work and enthusiasm that we continue to navigate towards a brighter future for the maritime industry.
          </p>
        </section>
      </Container>
    </div>
  );
};

export default AboutUs;
