// src/pages/Countries/Russia.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Russia.css'; // Custom CSS for Russia page
import russiaImage from '../../assets/Russia.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Russia = () => {
  return (
    <div className="russia-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={russiaImage} alt="Epsilon in Russia" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">From Novorossiysk to Global Waters</h1>
            <h2>Delivering Maritime Mastery</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Russia</h2>
        <hr className="group-divider" />

        <p>
          Russia's rich history, vast size, and remarkable achievements are deeply connected to its maritime heritage. This legacy has fostered
          the development of a highly skilled and competent seafaring workforce, particularly renowned for their expertise in the efficient
          operation of tanker vessels.
        </p>

        <p>
          Epsilon is proud to be represented in Russia through Epsilon Novo, our local office in Novorossiysk—one of the nation's key maritime
          gateways to global trade. From this strategic location, we provide access to Russia’s top maritime talent, ensuring excellence and
          reliability for our Principals worldwide.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="russia-contact container mt-5">
        <h3 className="text-center">Contact EPSILON SERVICES LTD</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>353925, Russia, Novorossiysk, 224/3 (Office 5) Pr. Dzerzhinskogo Yuzhnaya Str.</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+78617766140">+7 8617 76 61 40</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:recruitment@epsilonnovo.ru">recruitment@epsilonnovo.ru</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Russia;
