import React from 'react';
import Services from '../../components/Services/Services'; // Correct path to the Services component
import './ServicesPage.css'; // Optional: Add custom styling if needed

const ServicesPage = () => {
  console.log('Rendering ServicesPage'); // Debugging log

  return (
    <div className="services-page">
      <div className="container mt-5">
        <h1 className="text-center mb-4">Epsilon's Premier Maritime Solutions and Services</h1>
        <p className="lead text-center mb-5">
          Our services are expertly tailored to meet the distinct needs of our principals, offering unparalleled precision and customization. With an uncompromising dedication to quality and reliability, we provide an elite portfolio of maritime solutions designed to optimize performance and drive operational excellence. Through our innovative approach and deep industry expertise, we empower our clients to exceed expectations and set new benchmarks for success in the maritime sector.
        </p>
        <Services /> {/* This is where the Services component is being used */}
      </div>
    </div>
  );
};

export default ServicesPage;
