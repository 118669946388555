import React from 'react';
import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import './LegalStatement.css';

const sections = [
  {
    title: 'Introduction',
    content: (
      <p>
        This Legal Statement sets out the legal terms under which Epsilon Hellas (Overseas) Ltd operates this website and provides its services. By accessing this website, you agree to comply with these legal terms.
      </p>
    ),
  },
  {
    title: 'Intellectual Property',
    content: (
      <p>
        All content displayed on this website, including text, images, logos, and trademarks, are the intellectual property of Epsilon Hellas or third-party licensors. Unauthorized use, reproduction, or distribution of this content is strictly prohibited without prior written consent.
      </p>
    ),
  },
  {
    title: 'Limitation of Liability',
    content: (
      <p>
        Epsilon Hellas (Overseas) Ltd is not responsible for any damages arising out of the use or inability to use this website or the information it contains. While we strive to provide accurate information, we do not guarantee the completeness or accuracy of the content provided.
      </p>
    ),
  },
  {
    title: 'Applicable Law',
    content: (
      <p>
        This Legal Statement and all issues regarding this website shall be governed by and construed in accordance with the laws of Cyprus, without regard to its conflict of law provisions.
      </p>
    ),
  },
  {
    title: 'Changes to This Legal Statement',
    content: (
      <p>
        Epsilon Hellas reserves the right to modify this Legal Statement at any time. Changes will be posted on this page, and we encourage you to review this page periodically for any updates.
      </p>
    ),
  },
  {
    title: 'Contact Us',
    content: (
      <p>
        If you have any questions regarding this Legal Statement, please <a href="https://wfy24.com/contact">contact us here</a>.
      </p>
    ),
  },
];

const BackToTop = () => (
  <div className="text-center my-4">
    <Button variant="dark" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ArrowUp className="me-2" />
      Back to Top
    </Button>
  </div>
);

const LegalStatement = () => {
  return (
    <div className="legal-statement-container py-5">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <header className="legal-header text-center mb-5">
              <h1>Legal Statement</h1>
            </header>

            <Accordion defaultActiveKey="0">
              {sections.map((section, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>{section.content}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

            <BackToTop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LegalStatement;
