// src/pages/Countries/ThePhilippines.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './ThePhilippines.css'; // Custom CSS for Philippines page
import philippinesImage from '../../assets/Thephillipinnes.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const ThePhilippines = () => {
  return (
    <div className="philippines-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={philippinesImage} alt="Epsilon in the Philippines" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Where Filipino Expertise Meets Global Maritime Demands</h1>
            <h2>Leading with Excellence in Maritime Services</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">The Philippines</h2>
        <hr className="group-divider" />

        <p>
          The Philippines is globally esteemed as a premier maritime nation, renowned for producing outstanding seafaring talent. Filipino
          seafarers are recognized for their diligence, loyalty, and extensive expertise across a wide range of vessels. Their advanced skills,
          combined with their adaptability, have earned the trust of numerous Western and Japanese Principals, making the Philippines one of the
          most reliable sources of maritime human capital.
        </p>

        <p>
          Epsilon aspires to be the leading manning agency in the Philippines, providing a highly skilled and adaptable maritime workforce that
          addresses the evolving demands of the global shipping industry. Through comprehensive training programs, we foster professional growth
          and accelerate career advancement for our crew members.
        </p>
      </div>

      {/* Professional Contact Sections */}
      <div className="philippines-contact container mt-5">
        <h3 className="text-center">Contact Information</h3>

        {/* EPSILON MARITIME SERVICES INC. */}
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <h5>EPSILON MARITIME SERVICES INC.</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Vernida IV Bldg., 126, L.P Leviste Str. Salcedo Village, Makati City, Manila 1227 – Philippines</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+6328138000">+63 2 8138000</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:recruitment@epsilonmaritime.com.ph">recruitment@epsilonmaritime.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* KJCM MARITIME CORPORATION */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>KJCM MARITIME CORPORATION</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>11th Floor, Vernida IV Bldg., 128 L.P. Leviste St. Salcedo Village, Makati City, Manila 1227 – Philippines</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+6328299967">+63 2 8299967</a> or <a href="tel:+6328299963">+63 2 8299963</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:recruitment@kjcm.com.ph">recruitment@kjcm.com.ph</a> or <a href="mailto:art@kjcm.com.ph">art@kjcm.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* KJCM MARITIME CORPORATION - ILOILO BRANCH */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>KJCM MARITIME CORPORATION - ILOILO BRANCH</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Unit C, 2nd Floor, G&R BLDG, 33 M.H. Del Pilar, Brgy. Taal Molo, Iloilo City, 50000 Philippines</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+639688620579">+63 968 862 0579</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:iloilorecruitment@kjcm.com.ph">iloilorecruitment@kjcm.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* Cebu Branch */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>Epsilon Maritime Services Cebu Branch</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>G07 Don Alfredo D. Gothong Centre, Serging Osmena, North Reclamation Area, Cebu – Philippines</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+63998844079">+63 998844079</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:recruitment@epsilonmaritime.com.ph">recruitment@epsilonmaritime.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* Veritas Maritime Training Center */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>Veritas Maritime Training Center</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Atlantis Beacon Tower Corp., Zone 079, 2315 Leon Guinto St, Malate, Manila, 1004 Metro Manila</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+63285534709">+63 2 85534709</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:info@veritasmtc.com.ph">info@veritasmtc.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* Davao Branch */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>EPSILON MARITIME SERVICES DAVAO BRANCH</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Door 2, Ground Flr, M.L.C Crome Commercial Building, 129, Dacudao Avenue Brgy. 20B, Poblacion District 8000, Davao – Philippines</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+639088943427">+63 9088943427</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:epsilondavao@epsilonmaritime.com.ph">epsilondavao@epsilonmaritime.com.ph</a></p>
            </div>
          </div>
        </div>

        {/* VMTC Electronic Engine ME-C Site */}
        <div className="card contact-card mx-auto mt-4">
          <div className="card-body">
            <h5>VMTC Electronic Engine ME-C Site</h5>
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <p>Ground Floor, Atlantis Beacon Tower Corp., Zone 079, 2315 Leon Guinto St, Malate, Manila, 1004 Metro Manila</p>
            </div>
            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <p><a href="tel:+63285534709">+63 2 85534709</a></p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <p><a href="mailto:info@veritasmtc.com.ph">info@veritasmtc.com.ph</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThePhilippines;
