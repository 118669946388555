import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaCog, FaProjectDiagram, FaUsers, FaCode, FaLightbulb } from 'react-icons/fa';
import './RND.css';

const RND = () => {
  const initiatives = [
    {
      title: 'Innovative Crew Management Systems',
      description: "Developing advanced digital platforms for scheduling, onboarding, and monitoring crew welfare, ensuring seamless integration with Epsilon's global operations.",
      icon: <FaProjectDiagram />,
    },
    {
      title: 'Continuous Improvement Initiatives',
      description: "Following the PDCA (Plan-Do-Check-Act) process, we continuously optimize and enhance software to improve efficiency, align with business goals, and adapt to evolving maritime needs.",
      icon: <FaCog />,
    },
    {
      title: 'Sustainable Technology Development',
      description: "R&D explores and implements green technologies, focusing on reducing emissions and enhancing energy efficiency for vessels, aligned with Epsilon’s commitment to a sustainable future.",
      icon: <FaLightbulb />,
    },
    {
      title: 'Comprehensive Training Programs',
      description: "Our team develops manuals and conducts training sessions for Epsilon employees, ensuring that personnel are fully familiarized with new software and technologies through structured and user-friendly programs.",
      icon: <FaUsers />,
    },
    {
      title: 'Software Development Lifecycle Management',
      description: "We manage the entire software lifecycle—from planning and requirements gathering to testing and deployment—ensuring high-quality, efficient solutions tailored to Epsilon’s needs.",
      icon: <FaCode />,
    },
  ];

  return (
    <div className="rnd-section">
      <Container className="rnd-container mt-5">
        {/* Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">R&D at Epsilon Hellas</h1>
          <p className="lead motto">&quot;Empowering Epsilon through Innovation and Technology&quot;</p>
        </div>

        {/* Initiatives Overview */}
        <section className="initiatives-section">
          <Row className="g-4">
            {initiatives.map((initiative, index) => (
              <Col xs={12} md={6} lg={4} key={index}>
                <Card className="initiative-box">
                  <Card.Body>
                    <div className="initiative-icon">
                      {initiative.icon}
                    </div>
                    <h4 className="initiative-title">{initiative.title}</h4>
                    <p className="initiative-description">
                      {initiative.description}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </section>

        {/* Closing Statement */}
        <section className="closing-statement text-center mt-5">
          <p className="lead-text">
            At Epsilon Hellas, the R&amp;D department is at the forefront of technological advancement, ensuring our company remains competitive and innovative. Through rigorous development and continuous improvement processes, we enhance operational efficiency and support sustainable growth within the maritime industry.
          </p>
        </section>
      </Container>
    </div>
  );
};

export default RND;
