// src/dashboard/CreateInspection.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const CreateInspection = () => {
  const [vessels, setVessels] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState('');
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vesselsResponse = await api.get('/api/vessels/');
        const questionnairesResponse = await api.get('/api/questionnaires/');
        setVessels(vesselsResponse.data);
        setQuestionnaires(questionnairesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedVessel || !selectedQuestionnaire) {
      setError('Please select both a vessel and a questionnaire.');
      return;
    }
    try {
      const response = await api.post('/api/inspections/', {
        vessel: selectedVessel,
        questionnaire: selectedQuestionnaire,
      });
      navigate(`/dashboard/inspections/${response.data.id}`);
    } catch (error) {
      console.error('Error creating inspection:', error);
      setError('Failed to create inspection. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Create Inspection</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Vessel Selection */}
        <div className="mb-3">
          <label htmlFor="vessel" className="form-label">
            Vessel
          </label>
          <select
            id="vessel"
            className="form-select"
            value={selectedVessel}
            onChange={(e) => setSelectedVessel(e.target.value)}
            required
          >
            <option value="">Select a vessel</option>
            {vessels.map((vessel) => (
              <option key={vessel.id} value={vessel.id}>
                {vessel.name}
              </option>
            ))}
          </select>
        </div>
        {/* Questionnaire Selection */}
        <div className="mb-3">
          <label htmlFor="questionnaire" className="form-label">
            Questionnaire
          </label>
          <select
            id="questionnaire"
            className="form-select"
            value={selectedQuestionnaire}
            onChange={(e) => setSelectedQuestionnaire(e.target.value)}
            required
          >
            <option value="">Select a questionnaire</option>
            {questionnaires.map((q) => (
              <option key={q.id} value={q.id}>
                {q.name}
              </option>
            ))}
          </select>
        </div>
        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">
          Create Inspection
        </button>
      </form>
    </div>
  );
};

export default CreateInspection;
