import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const LetsMeetup = () => {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    nationality: '',
    nationalityOther: '',
    address: '',
    telephone: '',
    dateOfBirth: '',
    currentRank: '',
    currentRankOther: '',
    position: '',
    positionOther: '',
    preferredShipType: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNationalityChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      nationality: value,
      nationalityOther: value === 'Other' ? '' : prevData.nationalityOther,
    }));
  };

  const handleRankChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      currentRank: value,
      currentRankOther: value === 'Other' ? '' : prevData.currentRankOther,
    }));
  };

  const handlePositionChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      position: value,
      positionOther: value === 'Other' ? '' : prevData.positionOther,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form submission logic here
    console.log(formData);
  };

  return (
    <div className="container my-5">
      <h2 className="mb-4">Job Application</h2>
      <form onSubmit={handleSubmit} className="row g-3">
        {/* Name and Surname Fields */}
        <div className="col-md-6">
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="surname" className="form-label">Surname</label>
          <input
            type="text"
            className="form-control"
            id="surname"
            name="surname"
            value={formData.surname}
            onChange={handleChange}
            required
          />
        </div>
        {/* Email and Telephone Fields */}
        <div className="col-md-6">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="telephone" className="form-label">Telephone</label>
          <input
            type="tel"
            className="form-control"
            id="telephone"
            name="telephone"
            value={formData.telephone}
            onChange={handleChange}
          />
        </div>
        {/* Date of Birth Field */}
        <div className="col-md-6">
          <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
          <input
            type="date"
            className="form-control"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
          />
        </div>
        {/* Nationality Selection */}
        <div className="col-md-6 d-flex">
          <div className="flex-grow-1 me-3">
            <label htmlFor="nationality" className="form-label">Select Nationality</label>
            <select
              id="nationality"
              className="form-select"
              onChange={handleNationalityChange}
              value={formData.nationality}
            >
              <option value="">Select Nationality</option>
              <option value="Filipino">Filipino</option>
              <option value="Ukrainian">Ukrainian</option>
              <option value="Russian">Russian</option>
              <option value="Romanian">Romanian</option>
              <option value="Turkish">Turkish</option>
              <option value="Greek">Greek</option>
              <option value="Cypriot">Cypriot</option>
              <option value="Indonesian">Indonesian</option>
              <option value="Vietnamese">Vietnamese</option>
              <option value="Georgian">Georgian</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {formData.nationality === 'Other' && (
            <div className="flex-grow-1">
              <label htmlFor="nationalityOther" className="form-label">Nationality (Other)</label>
              <input
                type="text"
                className="form-control"
                id="nationalityOther"
                name="nationalityOther"
                value={formData.nationalityOther}
                onChange={handleChange}
                required
              />
            </div>
          )}
        </div>
        {/* Address Field */}
        <div className="col-md-6">
          <label htmlFor="address" className="form-label">Address</label>
          <input
            type="text"
            className="form-control"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </div>
        {/* Current Rank Selection */}
        <div className="col-md-6 d-flex">
          <div className="flex-grow-1 me-3">
            <label htmlFor="currentRank" className="form-label">Current Rank</label>
            <select
              id="currentRank"
              className="form-select"
              name="currentRank"
              value={formData.currentRank}
              onChange={handleRankChange}
            >
              <option value="">Select Current Rank</option>
              <option value="MASTER">MASTER</option>
              <option value="CHIEF OFFICER">CHIEF OFFICER</option>
              <option value="SECOND OFFICER">SECOND OFFICER</option>
              <option value="THIRD OFFICER">THIRD OFFICER</option>
              <option value="JUNIOR OFFICER">JUNIOR OFFICER</option>
              <option value="CHIEF ENGINEER">CHIEF ENGINEER</option>
              <option value="SECOND ENGINEER">SECOND ENGINEER</option>
              <option value="THIRD ENGINEER">THIRD ENGINEER</option>
              <option value="FOURTH ENGINEER">FOURTH ENGINEER</option>
              <option value="JUNIOR ENGINEER">JUNIOR ENGINEER</option>
              <option value="ELECTRICIAN">ELECTRICIAN</option>
              <option value="ELECTRICIAN/ETO">ELECTRICIAN/ETO</option>
              <option value="PUMPMAN">PUMPMAN</option>
              <option value="BOSUN">BOSUN</option>
              <option value="ABLE SEAMAN">ABLE SEAMAN</option>
              <option value="ORDINARY SEAMAN">ORDINARY SEAMAN</option>
              <option value="OILER">OILER</option>
              <option value="WIPER">WIPER</option>
              <option value="FITTER">FITTER</option>
              <option value="COOK">COOK</option>
              <option value="STEWARD">STEWARD</option>
              <option value="MESSMAN">MESSMAN</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {formData.currentRank === 'Other' && (
            <div className="flex-grow-1">
              <label htmlFor="currentRankOther" className="form-label">Current Rank (Other)</label>
              <input
                type="text"
                className="form-control"
                id="currentRankOther"
                name="currentRankOther"
                value={formData.currentRankOther}
                onChange={handleChange}
                required
              />
            </div>
          )}
        </div>
        {/* Position Selection */}
        <div className="col-md-6 d-flex">
          <div className="flex-grow-1 me-3">
            <label htmlFor="position" className="form-label">Position to Apply For</label>
            <select
              id="position"
              className="form-select"
              name="position"
              value={formData.position}
              onChange={handlePositionChange}
            >
              <option value="">Select Position</option>
              <option value="MASTER">MASTER</option>
              <option value="CHIEF OFFICER">CHIEF OFFICER</option>
              <option value="SECOND OFFICER">SECOND OFFICER</option>
              <option value="THIRD OFFICER">THIRD OFFICER</option>
              <option value="JUNIOR OFFICER">JUNIOR OFFICER</option>
              <option value="CHIEF ENGINEER">CHIEF ENGINEER</option>
              <option value="SECOND ENGINEER">SECOND ENGINEER</option>
              <option value="THIRD ENGINEER">THIRD ENGINEER</option>
              <option value="FOURTH ENGINEER">FOURTH ENGINEER</option>
              <option value="JUNIOR ENGINEER">JUNIOR ENGINEER</option>
              <option value="ELECTRICIAN">ELECTRICIAN</option>
              <option value="ELECTRICIAN/ETO">ELECTRICIAN/ETO</option>
              <option value="PUMPMAN">PUMPMAN</option>
              <option value="BOSUN">BOSUN</option>
              <option value="ABLE SEAMAN">ABLE SEAMAN</option>
              <option value="ORDINARY SEAMAN">ORDINARY SEAMAN</option>
              <option value="OILER">OILER</option>
              <option value="WIPER">WIPER</option>
              <option value="FITTER">FITTER</option>
              <option value="COOK">COOK</option>
              <option value="STEWARD">STEWARD</option>
              <option value="MESSMAN">MESSMAN</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {formData.position === 'Other' && (
            <div className="flex-grow-1">
              <label htmlFor="positionOther" className="form-label">Position (Other)</label>
              <input
                type="text"
                className="form-control"
                id="positionOther"
                name="positionOther"
                value={formData.positionOther}
                onChange={handleChange}
                required
              />
            </div>
          )}
        </div>
        {/* Preferred Ship Type */}
        <div className="col-md-12">
          <label htmlFor="preferredShipType" className="form-label">Preferred Type of Ship(s)</label>
          <select
            id="preferredShipType"
            className="form-select"
            name="preferredShipType"
            value={formData.preferredShipType}
            onChange={handleChange}
          >
            <option value="">Select Vessel Type</option>
            <option value="BULK CARRIER">BULK CARRIER</option>
            <option value="CHEMICAL">CHEMICAL</option>
            <option value="CONTAINER">CONTAINER</option>
            <option value="GENERAL CARGO">GENERAL CARGO</option>
            <option value="HEAVY LIFT">HEAVY LIFT</option>
            <option value="LNG">LNG</option>
            <option value="LPG">LPG</option>
            <option value="MULTI-PURPOSE">MULTI-PURPOSE</option>
            <option value="OFF-SHORE">OFF-SHORE</option>
            <option value="OIL/CHEMICAL">OIL/CHEMICAL</option>
            <option value="OIL TANKER">OIL TANKER</option>
            <option value="PASSENGER VESSEL">PASSENGER VESSEL</option>
            <option value="REEFER">REEFER</option>
            <option value="RIVERBOATS">RIVERBOATS</option>
            <option value="RO-RO">RO-RO</option>
            <option value="YACHT">YACHT</option>
            <option value="BARGE">BARGE</option>
            <option value="CAR CARRIER">CAR CARRIER</option>
            <option value="DYNAMIC POSITIONING">DYNAMIC POSITIONING</option>
            <option value="FISHING">FISHING</option>
            <option value="LIVESTOCK CARRIER">LIVESTOCK CARRIER</option>
            <option value="RECREATIONAL BOATS & CRAFTS">RECREATIONAL BOATS & CRAFTS</option>
            <option value="TUG">TUG</option>
            <option value="VLOC">VLOC</option>
            <option value="OTHER">OTHER</option>
          </select>
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default LetsMeetup