import React from 'react';
import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import './CookieStatement.css';

const sections = [
  {
    title: 'Introduction',
    content: (
      <p>
        This Cookie Statement explains how Epsilon Hellas (Overseas) Ltd uses cookies and similar tracking technologies on our website. By continuing to browse or use our site, you agree to the use of cookies as described in this statement.
      </p>
    ),
  },
  {
    title: 'What Are Cookies?',
    content: (
      <p>
        Cookies are small text files that are placed on your device to collect information about how you interact with websites. They enable websites to recognize your device and store some information about your preferences or past actions.
      </p>
    ),
  },
  {
    title: 'Types of Cookies We Use',
    content: (
      <ul>
        <li>
          <strong>Essential Cookies:</strong> These are necessary for the website to function correctly. Without these cookies, services like logging in cannot be provided.
        </li>
        <li>
          <strong>Performance Cookies:</strong> These cookies collect information about how visitors use the website, such as which pages are most popular, and if users are receiving error messages. The information collected is aggregated and anonymized.
        </li>
        <li>
          <strong>Functional Cookies:</strong> These cookies allow the website to remember choices you make, such as your language preference or region.
        </li>
        <li>
          <strong>Targeting Cookies:</strong> These cookies are used to deliver content more relevant to you and your interests. They may be used to deliver targeted advertising or limit the number of times you see an advertisement.
        </li>
      </ul>
    ),
  },
  {
    title: 'Managing Cookies',
    content: (
      <p>
        You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by adjusting the settings on your browser. If you choose to block cookies, some parts of our website may not work properly.
      </p>
    ),
  },
  {
    title: 'Third-Party Cookies',
    content: (
      <p>
        We may also allow third-party services to place cookies on your device. These cookies are used to track performance, display advertisements, and provide social media features. We do not control the use of these third-party cookies.
      </p>
    ),
  },
  {
    title: 'Changes to This Cookie Statement',
    content: (
      <p>
        We may update this Cookie Statement from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. Please revisit this Cookie Statement regularly to stay informed about our use of cookies.
      </p>
    ),
  },
  {
    title: 'Contact Us',
    content: (
      <p>
        If you have any questions about our use of cookies or this Cookie Statement, please <a href="https://wfy24.com/contact">contact us here</a>.
      </p>
    ),
  },
];

const BackToTop = () => (
  <div className="text-center my-4">
    <Button variant="dark" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ArrowUp className="me-2" />
      Back to Top
    </Button>
  </div>
);

const CookieStatement = () => {
  return (
    <div className="cookie-statement-container py-5">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <header className="cookie-header text-center mb-5">
              <h1>Cookie Statement</h1>
            </header>

            <Accordion defaultActiveKey="0">
              {sections.map((section, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>{section.content}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

            <BackToTop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CookieStatement;
