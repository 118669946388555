// Menu.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';
import LanguageSelector from './LanguageSelector';
import MyEpsilonButton from './MyEpsilonButton'; // Import MyEpsilonButton
import { useTranslation } from 'react-i18next';

function Menu() {
  const [isOpen, setIsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
  };

  // Close the menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isOpen &&
        !event.target.closest('.menu-panel') &&
        !event.target.closest('.hamburger')
      ) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <>
      {/* Desktop Menu */}
      <ul className="menu-nav desktop-menu">
        <li>
          <Link to="/about-us" className="menu-link">
            {t('ABOUT US')}
          </Link>
        </li>
        <li className="services-menu">
          <span className="menu-link" onClick={toggleServices}>
            {t('SERVICES')}
          </span>
          {/* Submenu for SERVICES */}
          {servicesOpen && (
            <ul className="submenu">
              <li>
                <Link to="/services/crew-management" className="menu-link">
                  {t('CREW MANAGEMENT')}
                </Link>
              </li>
              <li>
                <Link to="/services/crew-manning" className="menu-link">
                  {t('CREW MANNING')}
                </Link>
              </li>
              <li>
                <Link to="/services/training" className="menu-link">
                  {t('TRAINING')}
                </Link>
              </li>
              <li>
                <Link to="/services/pre-vetting-inspections" className="menu-link">
                  {t('PRE-VETTING INSPECTIONS')}
                </Link>
              </li>
              <li>
                <Link to="/services/technical-services" className="menu-link">
                  {t('TECHNICAL SERVICES')}
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/locations" className="menu-link">
            {t('LOCATIONS')}
          </Link>
        </li>
        <li>
          <Link to="/facts-figures" className="menu-link">
            {t('FACTS & FIGURES')}
          </Link>
        </li>
        <li>
          <Link to="/news-events" className="menu-link">
            {t('NEWS & EVENTS')}
          </Link>
        </li>
        <li>
          <Link to="/contact-us" className="menu-link">
            {t('CONTACT US')}
          </Link>
        </li>
        <li>
          <div className="menu-link">
            <LanguageSelector /> {/* Desktop Language Selector */}
          </div>
        </li>
        <li>
          <div className="menu-link">
            <MyEpsilonButton /> {/* Desktop MyEpsilonButton */}
          </div>
        </li>
      </ul>

      {/* Mobile Hamburger Menu */}
      <button
        className="hamburger"
        onClick={toggleMenu}
        aria-label="Toggle menu"
        aria-expanded={isOpen}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>

      <div className={`menu-overlay ${isOpen ? 'open' : ''}`}></div>

      <div className={`menu-panel ${isOpen ? 'open' : ''}`}>
        <button
          className="close-button"
          onClick={toggleMenu}
          aria-label="Close menu"
        >
          &times;
        </button>

        <div className="menu-language">
          <LanguageSelector /> {/* Mobile Language Selector */}
        </div>

        <hr className="menu-divider" />

        <ul className="menu-nav">
          <li>
            <Link to="/about-us" className="menu-link" onClick={closeMenu}>
              {t('ABOUT US')}
            </Link>
          </li>
          <li>
            <div className="menu-link" onClick={toggleServices}>
              {t('SERVICES')}
            </div>
            {/* Submenu for SERVICES */}
            {servicesOpen && (
              <ul className="submenu">
                <li>
                  <Link
                    to="/services/crew-management"
                    className="menu-link"
                    onClick={closeMenu}
                  >
                    {t('CREW MANAGEMENT')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/crew-manning"
                    className="menu-link"
                    onClick={closeMenu}
                  >
                    {t('CREW MANNING')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/training"
                    className="menu-link"
                    onClick={closeMenu}
                  >
                    {t('TRAINING')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/pre-vetting-inspections"
                    className="menu-link"
                    onClick={closeMenu}
                  >
                    {t('PRE-VETTING INSPECTIONS')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/technical-services"
                    className="menu-link"
                    onClick={closeMenu}
                  >
                    {t('TECHNICAL SERVICES')}
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/locations" className="menu-link" onClick={closeMenu}>
              {t('LOCATIONS')}
            </Link>
          </li>
          <li>
            <Link to="/facts-figures" className="menu-link" onClick={closeMenu}>
              {t('FACTS & FIGURES')}
            </Link>
          </li>
          <li>
            <Link to="/news-events" className="menu-link" onClick={closeMenu}>
              {t('NEWS & EVENTS')}
            </Link>
          </li>
          <li>
            <Link to="/contact-us" className="menu-link" onClick={closeMenu}>
              {t('CONTACT US')}
            </Link>
          </li>
          <li>
            <div className="menu-link" onClick={closeMenu}>
              <LanguageSelector /> {/* Mobile Language Selector */}
            </div>
          </li>
          <li>
            <div className="menu-link" onClick={closeMenu}>
              <MyEpsilonButton /> {/* Mobile MyEpsilonButton */}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
