import {
  FaGraduationCap,
  FaLaptop,
  FaGlobe,
  FaShip,
  FaMapMarkerAlt,
  FaPhoneAlt
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './TrainingServices.css';

const TrainingServices = () => {
  return (
    <div className="training-services-section">
      <Container className="training-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Training Services</h1>
          <p className="lead motto">&quot;Empowering Seafarers Through Excellence in Training.&quot;</p>
          <p className="lead-text">
            At Epsilon, we understand that investing in our people is investing in the future of maritime operations. Our commitment to training and development ensures that our seafarers are always prepared to meet the challenges of the industry, contributing to the overall safety, efficiency, and success of the vessels we manage.
          </p>
          <p className="lead-text">
            People matter &ndash; this is our fundamental belief, and we consider training to be an integral part of our crewing business. Ensuring that our seafarers are highly skilled and prepared is essential for the safety and smooth sailing of the vessels we manage, and we give this aspect of our operations our utmost attention.
          </p>
          <p className="lead-text">
            Epsilon operates three main training centers: in Manila, Constanta, and Odessa, alongside comprehensive online training courses. Each training center is equipped with state-of-the-art facilities, modern equipment, and experienced instructors to provide high-quality maritime training. Our training programs are designed to enhance the skills and knowledge of seafarers, keeping them aligned with the latest industry practices and technologies.
          </p>
          <p className="lead-text">
            In addition to our physical training centers, we also offer a wide range of online courses to ensure seafarers have access to continuous learning opportunities, no matter where they are. Our courses cover topics such as passage planning with the use of ECDIS, port state control, and more, ensuring that our crew members are equipped with the latest knowledge and skills required by the industry.
          </p>
        </div>

        {/* Our Training Centers Section */}
        <section className="training-centers mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Training Centers</h2>
          <Row className="g-4">
            {/* Manila Training Center */}
            <Col xs={12} sm={6} md={4}>
              <Card className="center-box">
                <Card.Body className="text-center">
                  <FaShip className="center-icon" />
                  <h3>Manila Training Center</h3>
                  <p>
                    Equipped with full mission dual bridge and engine simulators that work in integration, our Manila training center provides a realistic learning environment for seafarers to practice and refine their skills. The center also features a 2-stroke, 6-cylinder Electronic Engine Room System (ME-C) to offer hands-on training in engine operations.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Odessa Training Center */}
            <Col xs={12} sm={6} md={4}>
              <Card className="center-box">
                <Card.Body className="text-center">
                  <FaMapMarkerAlt className="center-icon" />
                  <h3>Odessa Training Center</h3>
                  <p>
                    The Odessa training center offers a wide range of courses, including Electronic Chart Display and Information System (ECDIS) training using equipment from leading manufacturers such as Transas, JRC, FURUNO, DANELEC, and INTERMARINE. The center&apos;s state-of-the-art simulators and experienced instructors help seafarers develop critical skills for safe navigation and operations.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Danube (Constanta) Training Center */}
            <Col xs={12} sm={6} md={4}>
              <Card className="center-box">
                <Card.Body className="text-center">
                  <FaGraduationCap className="center-icon" />
                  <h3>Danube (Constanta) Training Center</h3>
                  <p>
                    The Constanta training center is focused on providing advanced maritime training, including specialized courses like the Planned Maintenance System (PMS) training using Microsoft NAVDynamics, Benefit, Kapa, and Danaos systems. The center also features simulators that represent real-world scenarios to enhance the practical skills of our crew members.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Online Training Section */}
        <section className="online-training mb-5">
          <h2 className="premium-subheading text-center mb-4">Online Training</h2>
          <Row className="g-4">
            <Col xs={12} md={6}>
              <Card className="online-box">
                <Card.Body className="text-center">
                  <FaLaptop className="online-icon" />
                  <h3>Comprehensive Online Courses</h3>
                  <p>
                    Our online training programs ensure that seafarers have access to continuous learning opportunities, no matter where they are. Courses cover essential topics such as passage planning with ECDIS, port state control, and more, enabling crew members to stay updated with the latest industry standards and practices.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="online-box">
                <Card.Body className="text-center">
                  <FaGlobe className="online-icon" />
                  <h3>Global Accessibility</h3>
                  <p>
                    Our online platform provides flexible learning schedules, allowing seafarers to train at their own pace. This accessibility ensures that training does not interfere with operational duties while maintaining high standards of education and skill development.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Our Commitment Section */}
        <section className="commitment-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Commitment</h2>
          <p className="lead-text text-center">
            At Epsilon, we understand that investing in our people is investing in the future of maritime operations. Our commitment to training and development ensures that our seafarers are always prepared to meet the challenges of the industry, contributing to the overall safety, efficiency, and success of the vessels we manage.
          </p>
        </section>

        {/* Contact Section */}
        <section className="contact-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Contact Us</h2>
          <p className="lead-text text-center">
            Ready to empower your seafarers with our excellence in training? Contact our experts today to learn more about our training services.
          </p>
          <div className="text-center">
            <a href="/contact" className="btn btn-primary">
              <FaPhoneAlt className="me-2" />
              Get in Touch
            </a>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default TrainingServices;
