import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { People, Store, Public, Business, DirectionsBoat } from '@mui/icons-material';
import './Counters.css'; // Import the CSS for styling

function Counters() {
  // List of all items
  const items = [
    { icon: <People fontSize="large" />, title: 'Seafarers', count: '36,000+' },
    { icon: <Store fontSize="large" />, title: 'Branches', count: '18+' },
    { icon: <Public fontSize="large" />, title: 'Countries', count: '10+' },
    { icon: <Business fontSize="large" />, title: 'Principals', count: '120+' },
    { icon: <DirectionsBoat fontSize="large" />, title: 'Vessels', count: '1,100+' }
  ];

  return (
    <div className="counters-section">
      <div className="container-fluid p-0">
        {/* Full width with no padding */}
        <h4>Charting Success: Epsilon’s Key Achievements, Leading to New Horizons</h4>
        <div className="mb-4">
          <hr className="mx-auto" style={{ width: "80px", height: "4px", backgroundColor: "#007bff" }} />
        </div>
        <Row className="justify-content-center mt-5">
          {items.map((item, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={2} className="text-center mb-4">
              {item.icon}
              <h6 className="mt-2">{item.title}</h6>
              <h3>{item.count}</h3>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default Counters;
