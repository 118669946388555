// src/pages/Countries/Greece.js
import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Greece.css'; // Custom CSS for Greece page
import greeceImage from '../../assets/Greece.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Greece = () => {
  return (
    <div className="greece-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={greeceImage} alt="Epsilon in Greece" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Rooted in Tradition, Driven by Excellence</h1>
            <h2>Epsilon’s Commitment to Greece’s Maritime Legacy</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Greece</h2>
        <hr className="group-divider" />

        <p>
          Greece's deep and enduring connection with the sea is an integral part of the nation’s cultural identity.
          Epsilon was founded within, and continually draws inspiration from, this rich maritime tradition, which
          serves as both a guiding focus and a source of inspiration.
        </p>

        <p>
          Based in Glyfada, Greece, we are proud to be an elite company rooted in this maritime heritage. We view
          our presence in the country not only as a privilege but also as a profound responsibility—one that continually
          drives us to learn, evolve, and uphold the highest standards of excellence in everything we do at Epsilon.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="greece-contact container mt-5">
        <h3 className="text-center">Contact Epsilon Hellas</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>Leoforos Vouliagmenis, 120-122,<br />Glyfada, 16674, Greece</p>
              </div>
            </div>

            <div className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              <div>
                <h5>Phone</h5>
                <p><a href="tel:+302104551500">+30 210 45 51 500</a></p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:crew@epsilonhellas.com">crew@epsilonhellas.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Greece;
