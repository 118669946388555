import React from 'react';
import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import './DataProtectionPolicy.css';

const sections = [
  {
    title: 'Epsilon Hellas Data Protection Policy Statement',
    content: (
      <p>
        Epsilon Hellas will follow procedures to ensure that all employees, contractors, agents, consultants, and other parties who have access to any personal information held by or on behalf of us are fully aware of and abide by their duties and responsibilities under the Act.
      </p>
    ),
  },
  {
    title: 'Statement of Policy',
    content: (
      <p>
        We need to collect and use information about people with whom we work in order to carry out our business and provide our services. These may include members of the public, current, past, and prospective employees, clients, customers, and suppliers. All personal information must be handled in accordance with the DPA.
      </p>
    ),
  },
  {
    title: 'Data Protection Principles',
    content: (
      <>
        <p>
          We fully support and comply with the EU General Data Protection Regulation (GDPR). Personal information must be:
        </p>
        <ol>
          <li>Processed fairly, lawfully, and in a transparent manner;</li>
          <li>Collected for specified, explicit, and legitimate purposes;</li>
          <li>Adequate, relevant, and limited to what is necessary;</li>
          <li>Accurate and where necessary, kept up to date;</li>
          <li>Processed in a manner that ensures appropriate security of the personal data.</li>
        </ol>
      </>
    ),
  },
  {
    title: 'Collection and Use of Personal Data',
    content: (
      <>
        <p>Epsilon Hellas collects Personal Data relating to:</p>
        <ul>
          <li>Applicants for employment, full, part-time, and temporary employees;</li>
          <li>Contractors, clients, suppliers, and service providers;</li>
          <li>Users of our websites or other related services provided by Epsilon Hellas.</li>
        </ul>
      </>
    ),
  },
  {
    title: 'Disclosure of Personal Information',
    content: (
      <p>
        We apply strict conditions to the disclosure of personal information both internally and externally. We do not disclose personal information unless we believe it is lawful and necessary to do so. Confidentiality will be maintained where appropriate.
      </p>
    ),
  },
  {
    title: 'Handling of Personal Information',
    content: (
      <>
        <p>All staff will, through appropriate training and responsible management, ensure:</p>
        <ul>
          <li>Conditions regarding the lawful collection and use of personal information are followed;</li>
          <li>Accuracy and quality of personal information are maintained;</li>
          <li>Strict checks are applied to determine the length of time personal information is held;</li>
          <li>Appropriate security measures are taken to safeguard personal information.</li>
        </ul>
      </>
    ),
  },
];

const BackToTop = () => (
  <div className="text-center my-4">
    <Button variant="dark" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ArrowUp className="me-2" />
      Back to Top
    </Button>
  </div>
);

const DataProtectionPolicy = () => {
  return (
    <div className="data-protection-container py-5">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <header className="policy-header text-center mb-5">
              <h1>Data Protection Policy</h1>
            </header>

            <Accordion defaultActiveKey="0">
              {sections.map((section, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>{section.content}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

            <BackToTop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataProtectionPolicy;
