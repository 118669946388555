// src/pages/Countries/Vietnam.js
import React from 'react';
import { FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import './Vietnam.css'; // Custom CSS for Vietnam page
import vietnamImage from '../../assets/Vietnam.webp'; // Background image
import logoEpsilon from '../../assets/LogoEpsilon.png'; // Logo image

const Vietnam = () => {
  return (
    <div className="vietnam-container">
      {/* Background Image Section with Text Overlay */}
      <div className="container-fluid p-0 image-container">
        <div className="position-relative text-center text-white">
          <img src={vietnamImage} alt="Epsilon in Vietnam" className="img-fluid w-100 main-image" />
          {/* Logo on the image */}
          <div className="logo-overlay">
            <img src={logoEpsilon} alt="Epsilon Logo" className="epsilon-logo" />
          </div>
          {/* Text inside the image */}
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-4">Unlocking Vietnam’s Seafaring Potential</h1>
            <h2>For Global Shipping</h2>
          </div>
        </div>
      </div>

      {/* Full Article Content with Blue Divider */}
      <div className="container mt-5 text-left">
        <h2 className="our-services-title">Vietnam</h2>
        <hr className="group-divider" />

        <p>
          Vietnam is rapidly emerging as a leading powerhouse of seafaring talent, distinguished by its advanced maritime education infrastructure and
          its position as one of Southeast Asia’s fastest-growing investment hubs. The nation’s dynamic development and increasing global connectivity
          have made it a premier destination for cost-effective and high-quality crewing solutions.
        </p>

        <p>
          Epsilon’s operations are strategically based in Hai Phong, Vietnam’s key maritime hub. From this prime location, we expertly manage the
          preparation and deployment of highly skilled officers and crew, ensuring that our Principals benefit from Vietnam’s exceptional pool of
          maritime professionals.
        </p>
      </div>

      {/* Professional Contact Section */}
      <div className="vietnam-contact container mt-5">
        <h3 className="text-center">Contact Epsilon</h3>
        <div className="card contact-card mx-auto">
          <div className="card-body">
            <div className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              <div>
                <h5>Our Address</h5>
                <p>12th Floor, Akashi Building No 10 Block, 2A Le Hong Phong St, Ngo Quyen Dist, Hai Phong City – Vietnam</p>
              </div>
            </div>

            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <h5>Email</h5>
                <p><a href="mailto:contact@epsilonhellas.com">contact@epsilonhellas.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vietnam;
