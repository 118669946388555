import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaUsers, FaHandshake, FaGlobe, FaBriefcase } from 'react-icons/fa';
import './OurTeam.css';

const OurTeam = () => {
  return (
    <div className="epsilon-team-section">
      <Container className="epsilon-team mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">The Epsilon Team</h1>
          <p className="lead motto">&quot;Driving Maritime Success Through People and Passion&quot;</p>
        </div>

        {/* Team Introduction */}
        <section className="team-intro mb-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <p className="lead-text">
                Epsilon is a multinational company with a family ethos. Our team consists of experienced maritime professionals and is committed to excellence driven by a passion for the industry. The combination of maritime expertise, corporate ethos, industry insights, and innovative approaches is the key to delivering exceptional service. The collective experience and qualifications of our team are the reasons why clients and seafarers trust us.
              </p>
            </Col>
          </Row>
        </section>

        {/* Our Strengths Section */}
        <section className="our-strengths">
          <h2 className="premium-subheading text-center mb-5">Our Strengths</h2>
          <Row className="g-4">
            {/* Strength 1 */}
            <Col xs={12} md={6} lg={3}>
              <Card className="strength-box text-center">
                <Card.Body>
                  <FaUsers className="strength-icon" />
                  <h3>Extensive Network</h3>
                  <p>
                    With over 36,000 seafarers in our network, we ensure the best fit for every client.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Strength 2 */}
            <Col xs={12} md={6} lg={3}>
              <Card className="strength-box text-center">
                <Card.Body>
                  <FaGlobe className="strength-icon" />
                  <h3>Global Presence</h3>
                  <p>
                    Operating in 10 countries with 18 offices to support our clients worldwide.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Strength 3 */}
            <Col xs={12} md={6} lg={3}>
              <Card className="strength-box text-center">
                <Card.Body>
                  <FaHandshake className="strength-icon" />
                  <h3>Strong Partnerships</h3>
                  <p>
                    Partnerships with over 120 principals managing more than 1,100 vessels.
                  </p>
                </Card.Body>
              </Card>
            </Col>

            {/* Strength 4 */}
            <Col xs={12} md={6} lg={3}>
              <Card className="strength-box text-center">
                <Card.Body>
                  <FaBriefcase className="strength-icon" />
                  <h3>Industry Expertise</h3>
                  <p>
                    Our team’s collective experience and qualifications ensure exceptional service delivery.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Closing Statement */}
        <section className="closing-statement text-center mt-5">
          <p className="lead-text">
            At Epsilon, we believe that our people are the heart of our success. It is their passion, expertise, and dedication that drive us forward and help us deliver excellence to our clients every day. With over 36,000 seafarers in our network, 18 offices across 10 countries, and partnerships with over 120 principals managing more than 1,100 vessels, we are committed to providing the very best in crew management and maritime solutions. Our people are our greatest strength, and it is through their hard work and enthusiasm that we continue to navigate towards a brighter future for the maritime industry.
          </p>
        </section>
      </Container>
    </div>
  );
};

export default OurTeam;
