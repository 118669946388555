import {
  FaStethoscope,
  FaUserMd,
  FaHospital,
  FaMedkit,
  FaCertificate,
  FaCheckCircle,
  FaRegSmile,
  FaPhoneAlt
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './MedicalServices.css';

const MedicalServices = () => {
  return (
    <div className="medical-services-section">
      <Container className="medical-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Medical Services</h1>
          <p className="lead motto">&quot;Trusted Care, Superior Medical Standards&quot;</p>
          <p className="lead-text">
            Galinos Medical Clinic Inc. (GMCI) is committed to delivering high-quality healthcare services to seafarers and overseas Filipino workers (OFWs). Founded with the vision of becoming a premier medical provider, GMCI is fully accredited by the Department of Health (DOH) and adheres to the highest standards of care to ensure exceptional medical services for its clients.
          </p>
        </div>

        {/* About Galinos Medical Clinic Section */}
        <section className="about-gmci mb-5">
          <h2 className="premium-subheading text-center mb-4">About Galinos Medical Clinic</h2>
          <Row className="g-4">
            <Col xs={12}>
              <Card className="about-box">
                <Card.Body>
                  <p className="lead-text">
                    Galinos Medical Clinic Inc. (GMCI) was established by a group of visionary business leaders and professionals, spearheaded by President Atty. Elpidio Jamora and Medical Director Dr. Carolina Reyes. With a mission to provide premier healthcare services, GMCI serves sea-based and land-based companies, as well as individual clients, offering a comprehensive range of medical services with a strong emphasis on Pre-Employment Medical Examinations (PEME).
                  </p>
                  <p className="lead-text">
                    GMCI is dedicated to full compliance with the requirements of key government regulatory agencies, including the Department of Health (DOH), Philippine Overseas Employment Administration (POEA), Maritime Industry Authority (MARINA), Professional Regulation Commission (PRC), and Technical Education and Skills Development Authority (TESDA). The clinic is committed to achieving certification in international standards, ensuring quality assurance for PEME and prioritizing customer satisfaction.
                  </p>
                  <p className="lead-text">
                    Formerly known as Good Shepherd Diagnostic and Medical Center Corporation, GMCI officially rebranded on June 1, 2020, and secured its SEC registration on December 28, 2020. GMCI is also certified by DNV for ISO 9001:2015, implementing a robust quality management system to ensure excellence across its operations. By leveraging cutting-edge medical technology and a highly skilled medical team, GMCI delivers accurate results at competitive costs, solidifying its reputation as a trusted healthcare provider.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* The Galinos Mission and Vision Section */}
        <section className="mission-vision mb-5">
          <h2 className="premium-subheading text-center mb-4">The Galinos Mission and Vision</h2>
          <Row className="g-4">
            <Col xs={12} md={6}>
              <Card className="mission-box">
                <Card.Body className="text-center">
                  <FaCertificate className="mission-icon" />
                  <h3>Mission</h3>
                  <p>
                    To deliver high-quality healthcare services to local and overseas Filipino workers with dedication, integrity, and compassion, ensuring the well-being and satisfaction of every individual we serve.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="vision-box">
                <Card.Body className="text-center">
                  <FaRegSmile className="vision-icon" />
                  <h3>Vision</h3>
                  <p>
                    To become the most trusted and inspirational diagnostic clinic in the Philippines, renowned for providing world-class medical and laboratory services to both local and overseas Filipino workers.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Commitment to Excellence in Healthcare Quality Section */}
        <section className="commitment-excellence mb-5">
          <h2 className="premium-subheading text-center mb-4">Commitment to Excellence in Healthcare Quality</h2>
          <Row className="g-4">
            <Col xs={12} md={6}>
              <Card className="quality-policy-box">
                <Card.Body className="text-center">
                  <FaMedkit className="quality-icon" />
                  <h3>Quality Policy</h3>
                  <p>
                    Galinos Medical Clinic Inc. is committed to consistently delivering high-quality medical services that ensure complete customer satisfaction. We maintain a highly skilled medical team that adheres to all government regulations and medical standards. Through continuous upgrades to our medical equipment and facilities, and the implementation of an internationally recognized quality management system, we strive for ongoing service improvement and excellence.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card className="quality-objectives-box">
                <Card.Body className="text-center">
                  <FaCheckCircle className="objectives-icon" />
                  <h3>Quality Objectives</h3>
                  <p>
                    We are fully dedicated to achieving 100% compliance with all legal and regulatory requirements governing medical clinics. Our aim is to maintain a customer satisfaction rating of 90% or higher by continuously enhancing the quality of our services. Additionally, we guarantee the prompt delivery of Pre-Employment Medical Examination (PEME) results, ensuring they are provided within 24 hours of test completion. Furthermore, we are committed to achieving zero customer complaints, underscoring our focus on providing flawless service.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Our Services Section */}
        <section className="our-services mb-5">
          <h2 className="premium-subheading text-center mb-4">Our Services</h2>
          <Row className="g-4">
            <Col xs={12} sm={6} md={4}>
              <Card className="service-box">
                <Card.Body className="text-center">
                  <FaStethoscope className="service-icon" />
                  <h3>Pre-Employment Medical Examinations (PEME)</h3>
                  <p>
                    Comprehensive medical assessments to ensure the health and readiness of seafarers and OFWs before deployment.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="service-box">
                <Card.Body className="text-center">
                  <FaHospital className="service-icon" />
                  <h3>Diagnostic Services</h3>
                  <p>
                    Advanced diagnostic tools and experienced professionals to accurately identify and treat medical conditions.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Card className="service-box">
                <Card.Body className="text-center">
                  <FaUserMd className="service-icon" />
                  <h3>General Healthcare Services</h3>
                  <p>
                    Comprehensive healthcare services including routine check-ups, vaccinations, and emergency care.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Contact Section */}
        <section className="contact-section mb-5">
          <h2 className="premium-subheading text-center mb-4">Contact Us</h2>
          <p className="lead-text text-center">
            Ready to experience trusted care with superior medical standards? Contact Galinos Medical Clinic Inc. today to learn more about our services.
          </p>
          <div className="text-center">
            <a href="/contact" className="btn btn-primary">
              <FaPhoneAlt className="me-2" />
              Get in Touch
            </a>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default MedicalServices;
