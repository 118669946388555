// src/dashboard/InspectionsList.jsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';

const InspectionsList = () => {
  const [inspections, setInspections] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const response = await api.get('/api/inspections/');
        setInspections(response.data);
      } catch (error) {
        console.error('Error fetching inspections:', error);
      }
    };

    fetchInspections();
  }, []);

  const handleInspectionClick = (id) => {
    navigate(`/dashboard/inspections/${id}`);
  };

  const handleCreateInspection = () => {
    navigate('/dashboard/inspections/new');
  };

  return (
    <div className="container mt-5">
      <h2>Inspections</h2>
      <button className="btn btn-primary mb-3" onClick={handleCreateInspection}>
        Create New Inspection
      </button>
      <ul className="list-group">
        {inspections.map((inspection) => (
          <li
            key={inspection.id}
            className="list-group-item list-group-item-action"
            onClick={() => handleInspectionClick(inspection.id)}
            style={{ cursor: 'pointer' }}
          >
            {`Inspection of ${inspection.vessel.name} on ${inspection.date}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InspectionsList;
