import React from 'react';
import './JobApplyButton.css'; // Keep your custom CSS

function JobApplyButton() {
  return (
    <div className="job-apply-alert alert alert-secondary text-center">
      <p className="apply-text">
        Your pathway to global maritime success
        <a href="/contact" rel="noopener noreferrer">
          <button className="apply-button btn btn-primary ml-3">Apply for a Job</button>
        </a>
      </p>
    </div>
  );
}

export default JobApplyButton;
