import React from 'react';
import './Header.css';
import Logo from './Logo';
import Menu from './Menu';

function Header() {
  return (
    <header className="App-header d-flex justify-content-between align-items-center p-3">
      {/* Logo Section */}
      <div className="logo-section col-lg-2 col-md-3 col-sm-4">
        <Logo />
      </div>

      {/* Right-side content: Menu */}
      <div className="right-side d-flex align-items-center">
        <Menu /> {/* Menu handles both desktop and mobile menus */}
      </div>
    </header>
  );
}

export default Header;
