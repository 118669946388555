import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // Authentication Context Provider
import { HelmetProvider } from 'react-helmet-async'; // Head management for SEO
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome Icons

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider> {/* Wrap App with HelmetProvider for managing <head> */}
      <AuthProvider> {/* Wrap App with AuthProvider for authentication */}
        <App />
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// Only run reportWebVitals in development
if (process.env.NODE_ENV === 'development') {
  import('./reportWebVitals').then(({ default: reportWebVitals }) => {
    reportWebVitals();
  });
}
