import {
  FaUserTie,
  FaFileInvoiceDollar,
  FaPassport,
  FaPlane,
  FaFlag,
  FaFileAlt
} from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './ManningServices.css';

const ManningServices = () => {
  return (
    <div className="manning-services-section">
      <Container className="manning-services mt-5">
        {/* General Introduction Section */}
        <div className="text-center mb-5">
          <h1 className="premium-heading">Tailored Manning Solutions</h1>
          <p className="lead motto">&quot;Building Stronger Fleets with Skilled and Loyal Seafarers&quot;</p>
          <p className="lead-text">
            At Epsilon, we draw from an extensive pool of over 35,000 highly skilled seafarers from diverse nationalities and areas of expertise, providing tailored manning solutions that precisely align with the needs of discerning clients.
          </p>
          <p className="lead-text">
            Our Manning Services include comprehensive support with Union Negotiations, Ticketing, Crew Wages, Visas, Travel Arrangements, Flag Arrangements, and Documentation, offering our clients a complete, hassle-free experience.
          </p>
          <p className="lead-text">
            We are committed to providing top-notch seafarer care, training excellence, and long-term support, which has helped Epsilon build an enviable reputation for exceptional crew retention and loyalty.
          </p>
        </div>

        {/* Key Features Section */}
        <section className="premium-features">
          <h2 className="premium-subheading text-center mb-5">Key Aspects of Our Manning Services</h2>
          <Row className="text-center g-4">
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaUserTie className="feature-icon" />
                  <h3>Union Negotiations</h3>
                  <p>Expert handling of union agreements and negotiations.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaFileInvoiceDollar className="feature-icon" />
                  <h3>Crew Wages</h3>
                  <p>Comprehensive wage management according to CBA or non-CBA agreements.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaPassport className="feature-icon" />
                  <h3>Visa Support</h3>
                  <p>Complete support with visa processing.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaPlane className="feature-icon" />
                  <h3>Travel Arrangements</h3>
                  <p>Comprehensive support with ticketing and travel arrangements.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaFlag className="feature-icon" />
                  <h3>Flag Arrangements</h3>
                  <p>Efficient handling of flag arrangements.</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} className="d-flex">
              <Card className="feature-box flex-fill">
                <Card.Body>
                  <FaFileAlt className="feature-icon" />
                  <h3>Documentation</h3>
                  <p>Complete management of all necessary documentation.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default ManningServices;
