import { Container, Row, Col, Accordion, Button } from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import './TermsOfUse.css';

const sections = [
  {
    title: 'Introduction',
    content: (
      <p>
        Welcome to Epsilon Hellas LTD. These Terms of Use (the &ldquo;Terms&rdquo;) govern your use of our website located at [yourwebsite.com] (the &ldquo;Site&rdquo;). By accessing or using the Site, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Site.
      </p>
    ),
  },
  {
    title: 'Use of the Site',
    content: (
      <ul>
        <li>
          <strong>Eligibility:</strong> You must be at least 18 years old to use this Site. By using the Site, you represent and warrant that you are at least 18 years old.
        </li>
        <li>
          <strong>License:</strong> Subject to these Terms, Epsilon Hellas grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Site for your personal, non-commercial use.
        </li>
        <li>
          <strong>Prohibited Activities:</strong> You agree not to:
          <ul>
            <li>Use the Site for any unlawful purpose or in violation of any applicable laws.</li>
            <li>Engage in any activity that could disrupt or interfere with the functioning of the Site.</li>
            <li>Attempt to gain unauthorized access to any portion of the Site or its related systems.</li>
            <li>Use any automated means to access the Site for any purpose without our express written permission.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    title: 'Intellectual Property',
    content: (
      <p>
        All content, trademarks, logos, and intellectual property displayed on the Site are the property of Epsilon Hellas LTD or their respective owners. You are not permitted to use, reproduce, distribute, or create derivative works from any content without our explicit written consent.
      </p>
    ),
  },
  {
    title: 'User Content',
    content: (
      <ul>
        <li>
          <strong>Responsibility:</strong> You are solely responsible for any content you submit, post, or display on the Site.
        </li>
        <li>
          <strong>Rights Granted:</strong> By submitting content to the Site, you grant Epsilon Hellas a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, modify, and distribute your content.
        </li>
        <li>
          <strong>Prohibited Content:</strong> You agree not to submit any content that is unlawful, harmful, defamatory, infringing, or otherwise objectionable.
        </li>
      </ul>
    ),
  },
  {
    title: 'Termination',
    content: (
      <p>
        Epsilon Hellas reserves the right to terminate or suspend your access to the Site, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
      </p>
    ),
  },
  {
    title: 'Disclaimers',
    content: (
      <ul>
        <li>
          <strong>As-Is Basis:</strong> The Site is provided on an &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis without any warranties of any kind.
        </li>
        <li>
          <strong>No Warranty:</strong> Epsilon Hellas disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
        </li>
      </ul>
    ),
  },
  {
    title: 'Limitation of Liability',
    content: (
      <p>
        In no event shall Epsilon Hellas, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the Site.
      </p>
    ),
  },
  {
    title: 'Governing Law',
    content: (
      <p>
        These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.
      </p>
    ),
  },
  {
    title: 'Changes to Terms',
    content: (
      <p>
        Epsilon Hellas reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days&apos; notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
    ),
  },
  {
    title: 'Contact Us',
    content: (
      <p>
        If you have any questions about these Terms, please <a href="https://wfy24.com/contact">contact us here</a>.
      </p>
    ),
  },
];

const BackToTop = () => (
  <div className="text-center my-4">
    <Button variant="dark" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <ArrowUp className="me-2" />
      Back to Top
    </Button>
  </div>
);

const TermsOfUse = () => {
  return (
    <div className="terms-of-use-container py-5">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <header className="terms-header text-center mb-5">
              <h1>Terms of Use</h1>
            </header>

            <Accordion defaultActiveKey="0">
              {sections.map((section, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>{section.title}</Accordion.Header>
                  <Accordion.Body>{section.content}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>

            <BackToTop />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsOfUse;
