import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Define the locations as an array of objects with URL paths
const locations = [
    { name: 'The Philippines', lat: 12.8797, lng: 121.7740, url: '/the-philippines' },
    { name: 'Ukraine', lat: 48.3794, lng: 31.1656, url: '/ukraine' },
    { name: 'Russia', lat: 61.5240, lng: 105.3188, url: '/russia' },
    { name: 'Romania', lat: 45.9432, lng: 24.9668, url: '/romania' },
    { name: 'Turkey', lat: 38.9637, lng: 35.2433, url: '/turkey' },
    { name: 'Greece', lat: 39.0742, lng: 21.8243, url: '/greece' },
    { name: 'Cyprus', lat: 35.1264, lng: 33.4299, url: '/cyprus' },
    { name: 'Indonesia', lat: -0.7893, lng: 113.9213, url: '/indonesia' },
    { name: 'Vietnam', lat: 14.0583, lng: 108.2772, url: '/vietnam' },
    { name: 'Georgia', lat: 42.3154, lng: 43.3569, url: '/georgia' },
];

// Fix marker icon path for leaflet with React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const OurGlobalPresence = () => {
    return (
        <div style={{ height: '600px', width: '100%' }}>
            <MapContainer
                center={[20.0, 30.0]} // Center the map globally, avoiding the duplication effect
                zoom={2}
                style={{ height: '100%', width: '100%' }}
                worldCopyJump={false} // Prevents horizontal wrapping of the map
            >
                {/* Add a tile layer (map theme) */}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {/* Map through the locations and add a marker for each one */}
                {locations.map((location, idx) => (
                    <Marker key={idx} position={[location.lat, location.lng]}>
                        <Popup>
                            <a href={location.url}>{location.name}</a>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
};

export default OurGlobalPresence;
