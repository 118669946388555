import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FaArrowRight, FaRobot } from 'react-icons/fa'; // Import both arrow and robot icons

const SearchAI = () => {
  const [query, setQuery] = useState('');
  const [result, setResult] = useState('');

  const handleSearch = async () => {
    // Simulate an AI response for now
    const response = `AI Result for "${query}"`; // Replace with actual API call
    setResult(response);
  };

  return (
    <Container className="search-ai-container mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="What can I help with?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="me-2"
              aria-label="Search input" // Accessible label for the input field
            />
            <Button
              variant="primary"
              onClick={handleSearch}
              className="rounded-pill d-flex align-items-center justify-content-center"
              aria-label="Search button" // Accessible label for the button
            >
              <FaArrowRight aria-hidden="true" /> {/* Hide icon from screen readers */}
            </Button>
          </Form>
          {result && <div className="search-result mt-4">{result}</div>}
        </Col>
      </Row>

      <Row className="justify-content-center mt-4">
        <Col xs="auto" className="text-center">
          <p className="powered-by-ai">
            Powered by Epsilon AI <FaRobot className="ai-icon" aria-hidden="true" /> {/* Hide icon from screen readers */}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SearchAI;
