import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const News = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use REACT_APP_API_URL from environment variables
  const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://epsilon.wfy24.com';

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        console.log('API_BASE_URL:', API_BASE_URL);
        const fetchUrl = `${API_BASE_URL}/api/articles/news/`;
        console.log('Fetching URL:', fetchUrl);
        const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`Network response was not ok, status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Data received:', data);
        setArticles(data);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [API_BASE_URL]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-4">
        <div className="spinner-border" role="status" aria-hidden="true"></div>
        <span className="ms-2">Loading articles...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="alert alert-danger text-center" role="alert">
        {error}
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Latest News</h1>
      <div className="row">
        {articles.length > 0 ? (
          articles.map((article) => (
            <div className="col-lg-4 col-md-6 mb-4" key={article.id}>
              <div className="card h-100 d-flex flex-column shadow-sm">
                {article.image && (
                  <img
                    src={article.image}
                    className="card-img-top img-fluid"
                    style={{ objectFit: 'cover', height: '200px' }}
                    alt={article.title}
                  />
                )}
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">
                    <a
                      href={`/news/${article.slug}`}
                      className="text-decoration-none text-dark"
                    >
                      {article.title}
                    </a>
                  </h5>
                  <p className="card-text text-secondary">
                    {article.content.replace(/<[^>]*>?/gm, '').substring(0, 100)}...
                  </p>
                  <div className="mt-auto">
                    <a
                      href={`/news/${article.slug}`}
                      className="btn btn-sm read-more-btn"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                <div className="card-footer text-muted">
                  {new Date(article.published_date).toLocaleDateString('en-GB')}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">No articles found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default News;