import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaUsers, FaChalkboardTeacher, FaCogs, FaMedal, FaPeopleArrows, FaLeaf, FaHeartbeat, FaAnchor } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CardsComponent.css';

const CardsComponent = () => {
  const cards = [
    {
      title: "Excellence in Human Capital",
      description: "We place people at the core of what we do, ensuring a superior experience for all.",
      icon: <FaUsers />
    },
    {
      title: "State-of-the-Art Training",
      description: "Enhancing skills and competencies with our cutting-edge training programs.",
      icon: <FaChalkboardTeacher />
    },
    {
      title: "Adaptive Management and Cutting-Edge Technologies",
      description: "Innovating maritime operations through modern management and technology.",
      icon: <FaCogs />
    },
    {
      title: "Commitment to Quality and Continuous Improvement",
      description: "We strive for excellence by focusing on continuous development and improvement.",
      icon: <FaMedal />
    },
    {
      title: "Regulatory Compliance and Operational Integrity",
      description: "Ensuring all operations comply with regulations and maintaining the highest integrity.",
      icon: <FaPeopleArrows />
    },
    {
      title: "Developing People, Elevating Standards",
      description: "Empowering individuals while elevating the standards of the maritime industry.",
      icon: <FaLeaf />
    },
    {
      title: "Environmental Stewardship",
      description: "Promoting sustainable practices and safeguarding our oceans for future generations.",
      icon: <FaLeaf />
    },
    {
      title: "Healthcare",
      description: "Ensuring the health and well-being of our workforce with dedicated healthcare solutions.",
      icon: <FaHeartbeat />
    },
    {
      title: "Shaping the Future of Maritime Labor",
      description: "Pioneering the future of maritime labor with innovation and expertise.",
      icon: <FaAnchor />
    },
  ];

  return (
    <div className="cards-section py-5">
      <Container>
        {/* Cards Grid */}
        <Row className="g-4">
          {cards.map((card, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={4}>
              <Card className="service-card h-100 shadow-sm">
                <Card.Body className="d-flex flex-column align-items-center">
                  <div className="icon mb-3" style={{ fontSize: '2.5rem', color: '#1e96c1' }}>
                    {card.icon}
                  </div>
                  <Card.Title className="text-center mb-3">{card.title}</Card.Title>
                  <Card.Text className="flex-grow-1 text-center">{card.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CardsComponent;
